
import { toggleDebug, useAuthStore } from 'api/AuthContext';
import React from 'react';
import { Alert } from 'reactstrap';

const DebugView = () => {
  const { isDebug } = useAuthStore();

  if (isDebug) {
    return (
      <Alert toggle={toggleDebug} color='warning' style={{ position: 'fixed', right: '1em', bottom: '1em', zIndex: 1000, opacity: .4 }}>
        <h4 className='alert-heading'>Debug Mode Activated!</h4>
      </Alert>
    );
  }

  return null;
}

export default DebugView;