
export type OnProgressHandler = (progress: number) => void;

function progressHandler(onProgress: OnProgressHandler) {
  return function parseProgress(event: ProgressEvent<EventTarget>) {
    if (event.lengthComputable) {
      console.log('progress', event.loaded / event.total);
      onProgress(event.loaded / event.total);
    }
  }
}

export default async function uploadFile(signedRequest: string, file: File, onProgress?: OnProgressHandler) {
  return await new Promise<void>(resolve => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedRequest, true);
  
    if (onProgress) {
      const handler = progressHandler(onProgress);
      if (xhr.upload?.onprogress) {
        xhr.upload.onprogress = handler;
      }
  
      xhr.onprogress = handler;
      xhr.addEventListener('progress', handler);
    }
  
    xhr.addEventListener('load', () => {
      resolve();
    });
  
    xhr.addEventListener('error', (e) => {
      console.error('xhr error', e);
      resolve();
    });
  
    xhr.send(file);
  });
}