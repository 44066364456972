
import React, { useEffect, useState } from 'react';
import { Row, Col, CardBody, Card, CardHeader, CardTitle, CardSubtitle, CardText } from 'reactstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import LocationSearch, { Place } from 'components/custom/LocationSearch';
import CategorySelect, { Category } from 'components/custom/CategorySelect';
import ErrorBoundary from 'components/custom/ErrorBoundary';
import { gql, useQuery } from '@apollo/client';

const query = gql`
  query GetListings($slug: String! $latitude: Float $longitude: Float $zip: String) {
    listings(category_slug: $slug latitude: $latitude longitude: $longitude zip: $zip) {
      uuid
      name
      blurb
      description
      friendly_id
      distance
    }
  }
`;

export interface Listing {
  uuid: string;
  name: string;
  blurb: string;
  description: string;
  friendly_id: string;
  distance: number;
}

const Listing = ({ listing }: { listing: Listing }) => {
  const miles = false; // metersToMiles(listing.distance);

  return (
    <Link to={{state: { listing }, pathname: `/listings/${listing.uuid}`}}>
      <Card body>
        <CardTitle tag='h4'>
            { listing.name } { miles ? `(${miles} miles)` : ''}
        </CardTitle>
        <CardSubtitle>
            { listing.blurb }
        </CardSubtitle>
        <hr />
        <CardText>{ listing.description }</CardText>
      </Card>
    </Link>
  );
}

const SearchListings = () => {
  const { slug } = useParams<{ slug: string }>();
  const history = useHistory();
  const [category, setCategory] = useState<Category>();
  const [place, setPlace] = useState<Place>();
  const { data, loading } = useQuery<{listings: Array<Listing>}>(query, {
    variables: {
      slug: slug || category?.slug, 
      zip: place?.zip, 
      latitude: place?.location?.lat, 
      longitude: place?.location?.lon
    }
  });

  useEffect(() => {
    if (category) {
      history.replace(`/search/${category.slug}`);
    }
  }, [category]);

  return (
    <div className='content'>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col size={12} md={6}>
                  <CategorySelect onSelect={setCategory} />
                </Col>
                <Col size={12} md={6}>
                  <ErrorBoundary>
                    <LocationSearch place={place} onSelect={setPlace} />
                  </ErrorBoundary>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      { Boolean(data?.listings) && (
        <Row>
          <Col>
            <Card>
              <CardBody>
                { data?.listings.map((listing, i) => 
                  <Listing key={i} listing={listing} />
                ) }
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {/* <Row>
        <Col>
          <Card>
            <CardBody>
              <code>
                <pre>{ JSON.stringify({ category, place, data }, null, 2) }</pre>
              </code>
            </CardBody>
          </Card>
        </Col>
      </Row> */}
    </div>
  );
}

export default SearchListings;