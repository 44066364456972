
import React, { useContext, useEffect } from 'react';
import { get } from 'local-storage';
import { writeStorage, deleteFromStorage, useLocalStorage } from '@rehooks/local-storage';
import refreshTokens from './refreshTokens';
import { client } from './GraphQL';

export const DEBUG_MODE_KEY = 'HNP_DEBUG_MODE';
export const AUTH_TOKEN_KEY = 'HNP_AUTH_TOKEN';
export const REFRESH_TOKEN_KEY = 'HNP_REFRESH_TOKEN';
export const LAST_REFRESH_KEY = 'HNP_LAST_REFRESH';
export const REFRESH_INTERVAL_MS = 1000 * 30;
export const REFRESH_BUFFER_MS = 1000 * 60 * 2;

interface AuthStore {
  isDebug: boolean;
  isLoggedIn: boolean;
  isTokenExpired: boolean;
  tokens: {
    token?: string;
    refreshToken?: string;
    lastRefresh?: number;
  }
}

export async function login(token: string, refreshToken: string) {
  if (refreshToken) {
    writeStorage(AUTH_TOKEN_KEY, token);
    writeStorage(REFRESH_TOKEN_KEY, refreshToken);
    writeStorage(LAST_REFRESH_KEY, Date.now());
  } else {
    logout();
  }
}

export async function logout() {
  deleteFromStorage(AUTH_TOKEN_KEY);
  deleteFromStorage(REFRESH_TOKEN_KEY);
  deleteFromStorage(LAST_REFRESH_KEY);
  client.resetStore();
}

export function setDebug(debug: boolean) {
  writeStorage(DEBUG_MODE_KEY, debug);
}

export function toggleDebug() {
  writeStorage(DEBUG_MODE_KEY, !isDebug());
}

export function isDebug() {
  return get<boolean>(DEBUG_MODE_KEY);
}

export function getToken() {
  return get<string>(AUTH_TOKEN_KEY);
}

export function getTokenExpiry(jwtToken?: string) {
  const token = jwtToken || getToken();

  try {
    const parsed = JSON.parse(atob(token.split('.')[1]));
    return new Date(parsed.exp * 1000);
  } catch(e) {
    return new Date(0);
  }
}

export function isTokenExpired(jwtToken?: string) {
  return getTokenExpiry(jwtToken) < new Date();
}

export function getRefreshToken() {
  return get<string>(REFRESH_TOKEN_KEY);
}

export function getLastRefresh() {
  return get<number>(LAST_REFRESH_KEY);
}

const Context = React.createContext<AuthStore>({
  isDebug: isDebug(),
  isLoggedIn: Boolean(getRefreshToken()),
  isTokenExpired: isTokenExpired(),
  tokens: {
    token: getToken(),
    refreshToken: getRefreshToken(),
    lastRefresh: getLastRefresh()
  }
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isDebug] = useLocalStorage(DEBUG_MODE_KEY, false);
  const [token] = useLocalStorage(AUTH_TOKEN_KEY, '');
  const [refreshToken] = useLocalStorage(REFRESH_TOKEN_KEY, '');
  const [lastRefresh] = useLocalStorage(LAST_REFRESH_KEY, 0);

  useEffect(() => {
    refreshTokens();
  }, []);

  useEffect(() => {
    const interval = setInterval(refreshTokens, REFRESH_INTERVAL_MS);

    return function clearRefreshInterval() {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const store: AuthStore = {
    isDebug,
    isLoggedIn: Boolean(refreshToken),
    isTokenExpired: isTokenExpired(token),
    tokens: {
      token,
      refreshToken,
      lastRefresh
    }
  };

  return (
    <Context.Provider value={store}>
      { children }
    </Context.Provider>
  );
}

export function useAuthStore() {
  return useContext(Context);
}