
import React, { useMemo } from "react";
import { Container, Nav, NavItem, NavLink } from "reactstrap";

function Footer() {

  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <footer className="footer">
      <Container fluid>
        <Nav>
          <NavItem>
            <NavLink href="https://happyneighborhoodproject.com/">
              Happy Neighborhood Project
            </NavLink>
          </NavItem>
        </Nav>
        <div className="copyright">
          © {year} Happy Neighborhood Project. Stay Happy!
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
