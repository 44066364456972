
import React, { ElementRef, useCallback, useRef, useState } from 'react';
import createStripeCardToken from 'api/createStripeCardToken';
import { Alert } from 'reactstrap';
import Stripe from 'stripe';
import CreditCardInput, { CreditCardDetails } from './CreditCardInput';

interface StripeCreditCardInputProps {
  token?: Stripe.Token;
  onToken: (token?: Stripe.Token) => void;
  
}

function StripeCreditCardInput({ token, onToken }: StripeCreditCardInputProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const input = useRef<ElementRef<typeof CreditCardInput>>(null);

  const onCardDetails = useCallback(async (details: CreditCardDetails) => {
    setLoading(true);
    
    try {
      const token = await createStripeCardToken(details);
      onToken(token);
    } catch (e) {
      input.current?.reset();
    }

    setLoading(false);
  }, []);

  if (token) {
    return (
      <Alert color='success' toggle={() => onToken(undefined)}>
        { token.card?.brand } ending in { token.card?.last4 }, { token.card?.exp_month } / { token.card?.exp_year }
      </Alert>
    );
  }

  return (
    <CreditCardInput 
      ref={input}
      onCardDetails={onCardDetails} 
      loading={loading} 
      />
  )
}

export default StripeCreditCardInput;