
import React, { useCallback, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Row, Col } from 'reactstrap';
import UpdateListingsForm, { Listing } from 'widgets/UpdateListingsForm';
import cogoToast from 'cogo-toast';

const query = gql`
  query Me {
    username
    my_listings {
      name
      blurb
      description
      website
      phone
      email
      keywords
      happy_reward
      logo_uuid
    }
  }
`;

const mutation = gql`
  mutation UpdateListings($data: ListingUpdateData!) {
    update_listings(data: $data) {
      name
      blurb
      description
      website
      phone
      email
      keywords
      happy_reward
      logo_uuid
    }
  }
`;

const EditListing = () => {
  const { data } = useQuery(query);
  const [mutate, { data: mutationData }] = useMutation<{ update_listings: Array<Listing> }>(mutation);
  const [updated, setUpdated] = useState(Date.now());

  const onUpdate = useCallback(async data => {
    const { data: mutationData } = await mutate({ variables: { data } });
    const listing = mutationData?.update_listings[0];

    if (listing) {
      cogoToast.success('Successfully updated your listing!', {
        position: 'bottom-center'
      });
      setUpdated(Date.now());
    }
  }, [mutate, setUpdated]);

  const listing = mutationData?.update_listings[0] || data?.my_listings[0];

  return (
    <div className='content'>
      <Row>
        <Col>
          <h2>Edit Your Listing</h2>
          { Boolean(listing) && (
            <UpdateListingsForm 
              key={updated}
              currentListing={listing}
              onUpdate={onUpdate} 
              />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default EditListing;