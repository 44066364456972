
import React from 'react';
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router";
import CategorySelect from 'components/custom/CategorySelect';
import ErrorBoundary from 'components/custom/ErrorBoundary';
import LocationSearch from 'components/custom/LocationSearch';
import { Row, Col, Card, CardBody } from 'reactstrap';

const query = gql`
  query GetListing($uuid: ID!) {
    listing(uuid: $uuid) {
      name
      category
      logo
      blurb
      description
      phone
      website
      email
    }
  }
`;

function Logo({ url }: { url?: string }) {
  if (url) {
    return (
      <img src={url} style={{ maxHeight: 100, width: 250 }} />
    );
  }

  return null;
}

export default function Listing() {
  const { uuid } = useParams<{ uuid: string }>();
  const { data } = useQuery(query, {
    variables: { uuid }
  });

  return (
    <div className='content'>
      <Row>
        <Col>
          <Card body>
            <Logo url={data?.listing?.logo} />
            <hr />
            <p>Name: { data?.listing?.name }</p>
            <p>Category: { data?.listing?.category }</p>
            <p>Blurb: { data?.listing?.blurb }</p>
            <p>Description: { data?.listing?.description }</p>
            <p>Phone: <a href={`tel:${data?.listing?.phone}`}>{ data?.listing?.phone }</a></p>
            <p>Email: <a href={`mailto:${data?.listing?.email}`}>{ data?.listing?.email }</a></p>
            <p>Website: <a href={data?.listing?.website}>{ data?.listing?.website }</a></p>
          </Card>
        </Col>
      </Row>
    </div>
  );
}