
import { ApolloError, gql, useQuery } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { useEffect } from "react";
import { BusinessAccountType } from "views/Admin/RegisterBusiness";

const QUERY = gql`
  query Pricing ($type: BusinessAccountType! $zips: [String] $coupon: String) {
    new_pricing(type: $type zips: $zips coupon: $coupon) {
      total
      regular
      discount
      coupon
      type
    }
  }
`;

export interface Pricing {
  total: number;
  regular: number;
  discount: number;
  coupon: string;
  type: string;
}

interface PricingResponse {
  new_pricing: Pricing;
}

interface PricingProps {
  type?: BusinessAccountType;
  zips?: [string];
  coupon?: string;
  onError?: (error: ApolloError) => void;
}

export default function usePricing({ zips, coupon, type = BusinessAccountType.REMOTE, onError }: PricingProps) {
  const { loading, data, error } = useQuery<PricingResponse>(QUERY, {
    variables: {
      zips: zips || [], coupon, type
    }
  });

  useEffect(() => {
    if (error && onError) {
      onError(error);
    }
  }, [error]);

  return {
    loading,
    pricing: data?.new_pricing
  };
}

export function centsToDollars(cents: number = 0) {
  return `$${(cents / 100).toFixed(2)}`;
}