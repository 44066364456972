
import React from "react";
import classNames from "classnames";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import useUser from "api/useUser";
import { format } from "date-fns";

const query = gql`
  query GetAccount {
    account {
      type
      company
      name {
        first
        last
      }
      created_at
      contacts_remaining {
        remaining
        total
      }
    }
  }
`;

function getType(type: string) {
  //TODO: Pull totals & remaining from server
  if (type.includes('eagle'))
    return 'Eagle';

  if (type.includes('peacock'))
    return 'Peacock';

  if (type.includes('thryv'))
    return 'Thryv';

  return 'Dove';
}

function Dashboard() {
  const user = useUser();
  const { data } = useQuery(query, {
    fetchPolicy: 'cache-and-network'
  });

  return (
    <div className='content'>
      { Boolean(user && user.role !== 'user' && user.role !== 'guest') && (
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Link to='/admin/dashboard'>View Admin Dashboard</Link>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Card>
            { Boolean(data) ? (
              <CardBody>
                <CardTitle>
                  { data?.account?.name?.first } { data?.account?.name?.last }
                </CardTitle>
                <p>Plan: { getType(data?.account?.type) } ({ data?.account?.contacts_remaining?.total }  Neighbors / Month)</p>
                <p>Neighbors Remaining: { data?.account?.contacts_remaining?.remaining }</p>
                <p>Proud Member Since: { format(new Date(data?.account?.created_at || Date.now()), 'MMM do, yyyy')}</p>
              </CardBody>
            ) : null }
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card body className='text-center'>
            <div>
              <img src='/assets/hnp-decal.png' height={256} width={256} />
            </div>
            <br />
            <a style={{ marginTop: '1em' }} className='btn btn-default' href='/assets/hnp-decal.png' download='happy-neighborhood-project-decal.png'>Download our Decal</a>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
