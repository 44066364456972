
import React, { useState } from 'react'
import { SkyLightStateless } from 'react-skylight';
import { confirmable, createConfirmation } from 'react-confirm';
import { Card, Button, CardHeader, CardTitle, CardBody, Input, ButtonGroup, CardFooter, CardText } from 'reactstrap';

interface ModalProps {
  children?: any;
  show: boolean;
  dismiss: VoidFunction;
}

const Modal = ({ children, show, dismiss }: ModalProps) => (
  <SkyLightStateless
    onCloseClicked={dismiss}
    onOverlayClicked={dismiss}
    isVisible={show}
    dialogStyles={{backgroundColor: 'transparent', boxShadow: 'none'}}
    closeButtonStyle={{display: 'none'}}
    >
    <style>{'.mb0 { margin-bottom: 0; }'}</style>
    { children }
  </SkyLightStateless>
);

export default Modal;