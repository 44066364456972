
import React, { useCallback, useState } from 'react';
import useUser from 'api/useUser';
import { Row, Col, Form, FormGroup, Input, Label, Button, FormText, Card, CardBody, CardTitle, Alert, CardFooter } from 'reactstrap';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useHistory } from 'react-router-dom';
import Asterisk from 'components/custom/Asterisk';
import ControlledZipsInput from 'components/custom/ControlledZipsInput';

const register_mutation = gql`
  mutation RegisterBusiness(
      $business: BusinessInfoInput! 
      $category_uuid: ID!
      $type: BusinessAccountType!
      $zips: [String]) {
    create_pending_business(business: $business category_uuid: $category_uuid type: $type zips: $zips) {
      status
      business {
        uuid
        company
        name {
          first
          last
        }
        email
      }
    }
  }
`;

const categories_query = gql`
  query GetCategories {
    categories {
      name
      slug
      uuid
    }
  }
`;

export enum BusinessAccountType {
  REMOTE = 'REMOTE',
  LOCAL = 'LOCAL'
}

interface Category {
  name: string;
  slug: string;
  uuid: string;
}

interface CategoriesResponse {
  categories: Array<Category>;
}

function RegisterBusiness() {
  const user = useUser();
  const { register, control, handleSubmit, watch, errors } = useForm();
  const { data } = useQuery<CategoriesResponse>(categories_query);
  const [mutate, { data: mutationData, error, loading }] = useMutation(register_mutation, {
    errorPolicy: 'all'
  });
  const history = useHistory();

  const values = watch();

  const onSubmit = useCallback(async (variables: any) => {
    try {
      const { data } = await mutate({ variables });
  
      if (data?.create_remote_business?.business) {
        const { uuid } = data.create_remote_business.business;
        history.push(`/admin/register/payment/${uuid}`);
      }
    } catch (e) {
      console.error('create_pending_business');
    }
  }, [mutate, history]);

  return (
    <div className='content'>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <CardBody>
                <CardTitle>Register Business</CardTitle>
                <hr />
                <Input innerRef={register} name='business.ref_uuid' type='hidden' value={user?.account_uuid} />
                <FormGroup tag='fieldset'>
                  <Label>Account Type<Asterisk /></Label><br />
                  <FormGroup check inline>
                    <Label check>
                      <Input innerRef={register} type='radio' name='type' defaultChecked value={BusinessAccountType.REMOTE} />{' '}
                      Remote
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Label check>
                      <Input innerRef={register({ required: true })} type='radio' name='type' value={BusinessAccountType.LOCAL} />{' '}
                      Local
                    </Label>
                  </FormGroup>
                </FormGroup>
                <hr />
                <FormGroup>
                  <Label>Company Name<Asterisk /></Label>
                  <Input innerRef={register({ required: 'Company Name is required.' })} name='business.company' placeholder='Enter Company Name...' autoComplete='company' />
                  <ErrorMessage as={FormText} color='danger' name='business.company' errors={errors} />
                </FormGroup>
                <FormGroup>
                  <Label>Category<Asterisk /></Label>
                  <Input innerRef={register({ required: 'Please select a category...' })} type='select' name='category_uuid'>
                    <option disabled={values.category_uuid} value=''>Select a Category...</option>
                    { data?.categories.map((category: any) => (
                      <option key={category.uuid} value={category.uuid}>
                        { category.name }
                      </option>
                    ))}
                  </Input>
                  <ErrorMessage as={FormText} color='danger' name='category_uuid' errors={errors} />
                </FormGroup>
                { values.type === 'LOCAL' && (
                  <ControlledZipsInput 
                    name='zips'
                    control={control}
                    required={'Add at least one zip code or switch to remote.'}
                    errors={errors}
                    />
                )}
                <hr />
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormGroup>
                      <Label>First Name<Asterisk /></Label>
                      <Input innerRef={register({ required: 'First Name is required.' })} for='fname' name='business.name.first' placeholder='Enter First Name...' autoComplete='first_name' />
                      <ErrorMessage as={FormText} color='danger' name='business.name.first' errors={errors} />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormGroup>
                      <Label>Last Name<Asterisk /></Label>
                      <Input innerRef={register({ required: 'Last Name is required.' })} for='lname' name='business.name.last' placeholder='Enter Last Name...' autoComplete='last_name' />
                      <ErrorMessage as={FormText} color='danger' name='business.name.last' errors={errors} />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label>Email Address<Asterisk /></Label>
                  <Input innerRef={register({ required: 'Email Address is required' })} name='business.email' placeholder='name@example.com' autoComplete='email' />
                  <ErrorMessage as={FormText} color='danger' name='business.email' errors={errors} />
                </FormGroup>
                <FormGroup>
                  <Label>Phone Number</Label>
                  <Input innerRef={register} name='business.phone' placeholder='Enter Phone Number...' autoComplete='phone' />
                  <ErrorMessage as={FormText} color='danger' name='business.phone' errors={errors} />
                </FormGroup>
                <FormGroup>
                  <Label>Website</Label>
                  <Input innerRef={register} name='business.website' placeholder='https://example.com' autoComplete='website' />
                  <ErrorMessage as={FormText} color='danger' name='business.website' errors={errors} />
                </FormGroup>
                <hr />
                <FormGroup>
                  <Label>Street Address</Label>
                  <Input innerRef={register} name='business.address.street' placeholder='Enter Street Address...' />
                  <ErrorMessage as={FormText} color='danger' name='business.address.street' errors={errors} />
                </FormGroup>
                <Row>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <FormGroup>
                      <Label>City<Asterisk /></Label>
                      <Input innerRef={register({ required: 'Required' })} name='business.address.city' placeholder='Enter City...' />
                      <ErrorMessage as={FormText} color='danger' name='business.address.city' errors={errors} />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <FormGroup>
                      <Label>State / Province<Asterisk /></Label>
                      <Input innerRef={register({ required: 'Required' })} name='business.address.state' placeholder='Enter State or Province...' />
                      <ErrorMessage as={FormText} color='danger' name='business.address.state' errors={errors} />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <FormGroup>
                      <Label>Zip / Postal Code<Asterisk /></Label>
                      <Input innerRef={register({ required: 'Required' })} name='business.address.zip' placeholder='Enter Postal Code...' />
                      <ErrorMessage as={FormText} color='danger' name='business.address.zip' errors={errors} />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label>Country<Asterisk /></Label>
                  <Input innerRef={register({ required: true })} name='business.address.country' placeholder='Enter Country...' />
                  <ErrorMessage as={FormText} color='danger' name='business.address.country' errors={errors} />
                </FormGroup>
                { Boolean(error) && (
                  <div>
                    <Alert color='warning'>
                      { error }
                    </Alert>
                  </div>
                )}
                <CardFooter>
                  <Button type='submit' disabled={loading}>Submit</Button>
                </CardFooter>
              </CardBody>
            </Card>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default RegisterBusiness;