
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { getToken, isDebug, logout } from './AuthContext';

const API_URL = 'https://api.hnpabc.com';
const API_URL_DEV = 'https://hnp-api-dev.herokuapp.com';
const API_URL_DEV_LOCAL = 'http://localhost:5000';

const IS_LOCAL = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

export const getApiUrl = () =>
  !isDebug() ? API_URL : !IS_LOCAL ? API_URL_DEV : API_URL_DEV_LOCAL;

export const getGraphQlUrl = () => getApiUrl() + '/graphql';

const onErrorMiddleware = onError(({ graphQLErrors, response }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      // handle errors differently based on its error code
      switch (err.message) {
        case 'Invalid refresh token.':
          console.log('Invalid Refresh Token gql (not logged in)');
          if (response?.errors) {
            // delete response.errors;
            logout();
          }
        break;
        case 'Unauthorized':
          console.log('Unauthorized gql');
          break;
        default:
          console.log('onError gql', { err });
      }
    }
  };
});

const httpLink = new HttpLink({ 
  uri: getGraphQlUrl,
  fetchOptions: {
    onError: (args: any) => console.log('onError', args)
  },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: getToken() || null,
    }
  });

  return forward(operation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, onErrorMiddleware, httpLink]),
});

export function GraphQlProvider({ children }: { children: React.ReactNode }) {

  return (
    <ApolloProvider client={client}>
      { children }
    </ApolloProvider>
  );
}