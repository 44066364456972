
import React from 'react';
import { Container, Card, Button, CardBody, CardHeader, CardText, Spinner, CardFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import { useAuthStore, login as authLogin } from 'api/AuthContext';
import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Link, Redirect } from 'react-router-dom';

const mutation = gql`
  mutation Login($username: String! $password: String!) {
    login(username: $username password: $password) {
      token
      refresh_token
      user {
        username
        email
      }
    }
  }
`;

const Login = () => {
  const { isLoggedIn } = useAuthStore();

  const { register, handleSubmit } = useForm<LoginValues>();
  const onSubmit = (variables: LoginValues) => login({ variables });
  
  const [login, { data, loading, error }] = useMutation<LoginResponse>(mutation, {
    onError: () => {},
    onCompleted: data => {
      if (data.login) {
        const { token, refresh_token } = data.login;
        authLogin(token, refresh_token);
      }
    }
  });

  if (isLoggedIn) {
    return (
      <Redirect to='/' />
    );
  }

  return (
    <Container className='d-flex' style={{ height: '100vh', top: 0 }}>
      <div className='ml-auto mr-auto align-self-center'>
        <Card className='card-login'>
          <CardHeader>Log In!</CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Label for="username">Username</Label>
                <Input innerRef={register} type="text" name="username" id="username" autoComplete='username' />
              </FormGroup>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input innerRef={register} type="password" name="password" id="password" autoComplete='current-password' />
              </FormGroup>
              { loading ? 
                <Spinner /> :
                <Button type='submit'>
                  Login!
                </Button>
              }
              <hr />
              <Link to='/login/request-reset'>I Forgot My Password</Link>
            </Form>
          </CardBody>
          { Boolean(error) &&
            <CardFooter>
              <CardText>{ error?.message }</CardText>
            </CardFooter>
          }
          { Boolean(data) &&
            <CardFooter>
              <CardText>{ data?.login.token }</CardText>
            </CardFooter>
          }
        </Card>
      </div>
    </Container>
  );
}

export default Login;