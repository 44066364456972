
import { HnpFile } from 'api/files/useFile';
import React from 'react';
import { OnRemoveFunction } from './ControlledFileUploadInput';
import HookFormFileInput, { HookFormFileInputProps } from './HookFormFileInput';

function renderFile(file: HnpFile, onRemove?: OnRemoveFunction) {
  return (
    <div className='text-center'>
      <img 
        alt={file.key}
        src={file.url} 
        width={256}
        />
    </div>
  );
}

function HookFormImageFileInput ({ name, control, defaultValue, label }: HookFormFileInputProps) {

  return (
    <HookFormFileInput
      name={name}
      control={control}
      defaultValue={defaultValue}
      label={label}
      renderFile={renderFile}
      />
  );
}

export default HookFormImageFileInput;