
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { debounce, throttle } from 'throttle-debounce';
import classNames from 'classnames';
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { id } from 'date-fns/locale';
import ReactDropdown from 'react-dropdown';

export interface Place {
  name: string;
  zip?: string;
  location?: {
    lat: number;
    lon: number;
  }
  city?: string;
  state?: string;
  country: string;
}

type OnSelectHandler = (place?: Place) => void;

interface LocationSearchProps {
  place?: Place;
  onSelect: OnSelectHandler;
}

const PlaceResult = ({ place, onSelect }: { place: Place, onSelect: OnSelectHandler }) => {

  return (
    <button className='btn btn-link btn-block' onClick={() => onSelect(place)}>
      { place.name }{' '}
      { Boolean(place.city) ?
        <small>{ place.city }, { place.state }</small> :
        <small>{ place.state }, { place.country }</small> 
      }
    </button>
  );
}

export default function LocationSearch({ place, onSelect = () => {} }: LocationSearchProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [result, setResult] = useState<Array<Place>>([]);
  const [lastSearched, setLastSearched] = useState(new Date());
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(open => !open);

  const updateResult = useCallback((features: Array<any>, searched: Date) => {
    if (searched > lastSearched) {
      setResult(features.map(item => {
        return {
          name: item?.properties?.name,
          location: {
            lon: item?.geometry?.coordinates[0],
            lat: item?.geometry?.coordinates[1]
          },
          city: item?.properties?.city,
          state: item?.properties?.state,
          country: item?.properties?.country
        };
      }));
    }
  }, [lastSearched]);

  const searchLocation = useMemo(() => debounce(444, async (query: string, limit: number = 5) => {
    const searchTime = new Date();
    setLastSearched(searchTime);
    setLoading(true);

    const response = await fetch(`https://photon.komoot.io/api/?q=${query}&limit=${limit}`);
    const result = await response.json();
    updateResult(result?.features, searchTime);

    setLoading(false);
  }), []);

  const options = useMemo(() => result.length ? result.map(res => ({
    value: res.name,
    label: res.name
  })) : [], [result]);

  useEffect(() => {
    if (!Number.isNaN(parseInt(query, 10)) && query.length <= 5) {
      if (query.length === 5) {
        const place = {
          name: `Zip Code: ${query}`,
          zip: query,
          country: 'United States'
        };

        setResult([place]);
      }
    } else if (query) {
      searchLocation(query);
    } else {
      setResult([]);
    }
  }, [query, searchLocation]);

  if (place) {
    return (
      <InputGroup>
        <Input 
          value={place?.name}
          readOnly
          disabled
          />
        <InputGroupAddon addonType="append">
          <Button size='sm' onClick={() => onSelect()}>X</Button>
        </InputGroupAddon>
      </InputGroup>
    );
    
    return (
      <span className='chip'>
        { place?.name }
        <button className='btn btn-clear' aria-label='Close' role='button' onClick={() => onSelect()}></button>
      </span>
    );
  }

  return (
    <div>
      <input 
        id='location-search-popup'
        className='form-control'
        placeholder='Enter your zip/location...'
        value={query} 
        onBlur={toggle}
        onFocus={() => setPopoverOpen(true)}
        onChange={e => setQuery(e.currentTarget.value)} 
        />
        {
          !Boolean(result.length) ?
            <p className='text-center'>Enter your zip code or search for your location.</p> :
            <div className='bg-light position-absolute float-right' style={{ width: '90%' }}>
              { result.map((place, i) => <PlaceResult key={i} place={place} onSelect={onSelect} />) }
            </div>
        }
    </div>
  );
}