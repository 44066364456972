
import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Row, Col } from 'reactstrap';
import BusinessesTableCard from 'widgets/BusinessesTableCard';

const query = gql`
  query Meetings {
    businesses {
      company
      type
      name {
        first
        last
      }
      created_at
    }
  }
`;

const Businesses = () => {
  const { data } = useQuery(query, {
    fetchPolicy: 'cache-and-network'
  });

  return (
    <div className='content'>
      <Row>
        <Col>
          <BusinessesTableCard />
        </Col>
      </Row>
    </div>
  );
}

export default Businesses;