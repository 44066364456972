
import React from 'react';
import { Container, Card, Button, CardBody, CardHeader, CardText, Spinner, CardFooter, Form, FormGroup, Input, Label, CardTitle } from 'reactstrap';
import { login as authLogin } from 'api/AuthContext';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Redirect, useParams } from 'react-router-dom';

const query = gql`
  query NewUser($magic_code: String!) {
    new_user(magic_code: $magic_code) {
      username
      email
      phone
    }
  }
`;

interface NewUserResponse {
  [key: string]: {
    username: string;
    email: string;
    phone: string;
  }
}

const mutation = gql`
  mutation Onboard($magic_code: String! $username: String $password: String!) {
    onboard_user(magic_code: $magic_code username: $username password: $password) {
      token
      refresh_token
      user {
        username
        email
      }
    }
  }
`;

const Onboard = () => {
  const { magic_code } = useParams<{ magic_code: string }>();

  const { register, handleSubmit, getValues, errors, formState: { isValid }, trigger } = useForm<OnboardValues>({
    mode: 'all'
  });

  const { data: user_data } = useQuery<NewUserResponse>(query, {
    variables: { magic_code }
  });
  
  const [onboard, { data, loading, error }] = useMutation<LoginResponse>(mutation, {
    onError: () => {},
    onCompleted: data => {
      if (data.onboard_user) {
        const { token, refresh_token } = data.onboard_user;
        authLogin(token, refresh_token);
      }
    }
  });

  const onSubmit = (variables: OnboardValues) => onboard({ variables });

  if (data?.onboard_user.refresh_token) {
    return (
      <Redirect to='/listing' />
    );
  }

  return (
    <Container className='d-flex' style={{ height: '100vh', top: 0, justifyContent: 'center' }}>
      <div className='align-self-center col-4'>
        <Card className='card-login'>
          <CardHeader>
            <CardTitle tag="h4" className='text-center'>Set your password</CardTitle>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Input innerRef={register({ required: true })} type="hidden" name="magic_code" id="magic_code" value={magic_code} />
              <h2>{ user_data?.new_user?.username }</h2>
              {/*
              <FormGroup>
                <Label for="username">Username</Label>
                <Input innerRef={register} type="text" name="username" id="username" placeholder={user_data?.new_user?.username} defaultValue={user_data?.new_user?.username} autoComplete='username' />
                <ErrorMessage as='span' className='text-danger' errors={errors} name="username" />
              </FormGroup>
              */}
              <FormGroup>
                <Label for="password">Password</Label>
                <Input innerRef={register({
                  required: 'Please enter a password.',
                  minLength: {
                    value: 8,
                    message: 'Password must be at least 8 characters long.'
                  }
                })} type="password" name="password" id="password" onBlur={() => trigger()} autoComplete='new-password' />
                <ErrorMessage as='span' className='text-danger' errors={errors} name="password" />
              </FormGroup>
              <FormGroup>
                <Label for="confirmpassword">Confirm Password</Label>
                <Input innerRef={register({
                  required: 'Please confirm your password.',
                  validate: value => value === getValues('password') || 'Passwords do not match.'
                })} type="password" name="confirmpassword" id="confirmpassword" onBlur={() => trigger()} autoComplete='new-password' />
                <ErrorMessage as='span' className='text-danger' errors={errors} name="confirmpassword" />
              </FormGroup>
              { loading ? 
                <Spinner /> :
                <Button size='block' disabled={loading || !isValid} type='submit'>
                  Login!
                </Button>
              }
            </Form>
          </CardBody>
          { Boolean(error) &&
            <CardFooter>
              <CardText>{ error?.message }</CardText>
            </CardFooter>
          }
          { Boolean(data) &&
            <CardFooter>
              <CardText>{ data?.login.token }</CardText>
            </CardFooter>
          }
        </Card>
      </div>
    </Container>
  );
}

export default Onboard;