
import { useState, useEffect } from 'react';

const useDebounce = (value: any, ms: number = 300) => {
    const [state, setState] = useState(value);

    useEffect(() => {
        const timeout = setTimeout(() => setState(value), ms);

        return () => clearTimeout(timeout);
    }, [value, ms]);

    return state;
}

export default useDebounce;