
import { gql, useQuery } from "@apollo/client";
import { useLocalStorage } from '@rehooks/local-storage';
import { useEffect } from "react";

const USER_DATA_KEY = 'HNP_USER_DATA';

const query = gql`
  query GetUser {
    me {
      uuid
      account_uuid
      username
      email
      role
    }
  }
`;

export type UserRole = 'guest' | 'user' | 'sales' | 'manager' | 'admin' | 'superadmin';

export interface User {
  uuid: string;
  account_uuid: string;
  username: string;
  email: string;
  role: UserRole;
}

export default function useUser() {
  const { data } = useQuery<{ me: User }>(query, {
    fetchPolicy: 'cache-and-network'
  });
  const [user, setUser, removeUser] = useLocalStorage<User>(USER_DATA_KEY);

  useEffect(() => {
    if (data?.me) {
      setUser(data.me);
    } else if (data) {
      removeUser();
    }
  }, [data, setUser, removeUser]);

  return user || undefined;
}

export function useCheckRole(...roles: Array<UserRole>) {
  const user = useUser();

  if (roles.indexOf(user?.role || 'guest') > -1) {
    return true;
  }

  return false;
}

export function useIsAdministrator() {
  return useCheckRole('superadmin', 'admin', 'manager', 'sales');
}