
import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Row, Col } from 'reactstrap';
import CohostsTableCard from 'widgets/CohostsTableCard';
import UpdateCohostForm from 'widgets/UpdateCohostForm';
import Modal from 'components/custom/Modal';
import cogoToast from 'cogo-toast';

const query = gql`
  query Meetings {
    meetings {
      location
      meeting_id
    }
  }
`;

const addCohostMutation = gql`
  mutation AddCohost($data: CohostInputData!) {
    add_cohost(data: $data) {
      uuid
      name
      email
      phone
      company
      website
      meeting_id
      started_at
      ended_at
      mentor_uuid
    }
  }
`;

const updateCohostMutation = gql`
  mutation UpdateCohost($cohost_uuid: String! $data: CohostInputData!) {
    update_cohost(cohost_uuid: $cohost_uuid data: $data) {
      uuid
      name
      email
      phone
      company
      website
      meeting_id
      started_at
      ended_at
      mentor_uuid
    }
  }
`;

const CoHosts = () => {
  const { data } = useQuery(query);
  const [addCohost] = useMutation(addCohostMutation);
  const [updateCohost] = useMutation(updateCohostMutation);
  const [updating, setUpdating] = useState();

  return (
    <div className='content'>
      { Boolean(updating) && 
        <Modal show={Boolean(updating)} dismiss={() => setUpdating(undefined)}>
          <div style={{ maxHeight: '50vh', overflow: 'scroll' }}>
            <UpdateCohostForm 
              currentCohost={updating}
              onUpdate={async (data) => { 
                setUpdating(undefined);
                cogoToast.success('Updated Co-Host!', { position: 'bottom-center' });
                const { uuid: cohost_uuid, ...rest } = data;
                const result = await updateCohost({ variables: { cohost_uuid, data: rest }});
                return result.data?.add_cohost;
              }} 
              />
          </div>
        </Modal>
      }
      <Row>
        <Col>
          <UpdateCohostForm 
            onUpdate={async ({ uuid, ...data }) => { 
              const result = await addCohost({ variables: { data }});
              return result.data?.add_cohost;
            }} 
            />
        </Col>
      </Row>
      <Row>
        <Col>
          <CohostsTableCard onUpdating={setUpdating} />
        </Col>
      </Row>
    </div>
  );
}

export default CoHosts;