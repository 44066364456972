
import React from "react";
import { Switch, Redirect } from "react-router-dom";

import routes, { getRoutes } from "routes";

interface FullscreenLayoutProps {
  layout: string;
  redirect?: string;
}

function Fullscreen({ layout, redirect }: FullscreenLayoutProps) {

  return (
    <div className='wrapper wrapper-full-page'>
      <Switch>
        {getRoutes(routes, layout)}
        <Redirect from="*" to={redirect || layout} />
      </Switch>
    </div>
  );
}

export default Fullscreen;
