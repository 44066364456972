
import React from 'react';
import { Row, Col } from 'reactstrap';
import PastMeetingsTableCard from 'widgets/PastMeetingsTableCard';

const PastMeetings = () => {

  return (
    <div className='content'>
      <Row>
        <Col>
          <PastMeetingsTableCard
            />
        </Col>
      </Row>
    </div>
  );
}

export default PastMeetings;