
import { gql, useMutation, useQuery } from '@apollo/client';
import { string } from 'prop-types';
import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import { Row, Col } from 'reactstrap';
import UpdateEventForm, { HnpEvent } from 'widgets/UpdateEventForm';

const query = gql`
  query GetEvent($uuid: ID!) {
    event(uuid: $uuid) {
      uuid
      title
      instructor
      description
      start
    }
  }
`;

const mutation = gql`
  mutation UpdateEvent($uuid: ID! $event: EventInput!) {
    update_event(uuid: $uuid event: $event) {
      title
      description
    }
  }
`;

const EditEvent = () => {
  const { uuid } = useParams<{ uuid: string; }>();
  const { data } = useQuery(query, {
    variables : { uuid }
  });
  const [editEvent] = useMutation(mutation, {
    errorPolicy: 'none'
  });

  const submit = useCallback(async (event: HnpEvent) => {
    delete event.uuid;
    const result = await editEvent({ variables: { uuid, event }});
    console.log({ event, result });
    return result?.data?.update_event;
  }, [editEvent, uuid]);

  return (
    <div className='content'>
      <Row>
        <Col>
          <UpdateEventForm key={data?.event?.uuid} currentEvent={data?.event} onUpdate={submit} />
        </Col>
      </Row>
    </div>
  );
}

export default EditEvent;