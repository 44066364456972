
import React, { useMemo } from 'react';
import DataTable from 'components/Table/DataTable';
import { gql, useQuery } from '@apollo/client';
import { format } from 'date-fns';

const query = gql`
  query Neighbors {
    get_contacts {
      company
      name
      email
      phone
      meeting_location
      meeting_date
      created_at
    }
    account {
      type
      contacts_remaining {
        remaining
        total
      }
    }
  }
`;

export default function NeighborsTableCard() {
  const { data } = useQuery(query, {
    fetchPolicy: 'cache-and-network'
  });

  const columns = useMemo(() => [{
    Header: 'Company',
    accessor: 'company'
  }, {
    Header: 'Name',
    accessor: 'name'
  }, {
    Header: 'Email',
    accessor: 'email'
  }, {
    Header: 'Phone',
    accessor: 'phone'
  }, {
    Header: 'Meeting',
    accessor: 'meeting_location',
    Cell: ({ value, row }: { value: string, row: any }) => `${value} (${row?.original?.meeting_date})`
  }, {
    Header: 'Added',
    accessor: 'created_at',
    Cell: ({ value, row }: { value: number, row: any }) => value ? 
      format(new Date(value), 'MMM do, yyyy') : 
      ''
  }], []);

  const contacts = useMemo(() => data?.get_contacts || [], [data]);

  return (
    <DataTable
      title={`Neighbors (${data?.account?.contacts_remaining?.remaining || '-'} Remaining This Month)`}
      data={contacts} 
      columns={columns} 
      initialSort='created_at'
      />
  );
}