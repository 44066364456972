
import React from 'react';
import { Container, Card, Button, CardBody, CardHeader, CardText, Spinner, CardFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import { useAuthStore } from 'api/AuthContext';
import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Redirect, useLocation } from 'react-router-dom';

const mutation = gql`
  mutation ResetPassword($email: String! $secret: String! $password: String!) {
    reset_password(email: $email secret: $secret password: $password)
  }
`;

const ResetPassword = () => {
  const { isLoggedIn } = useAuthStore();
  const { state } = useLocation<{ email: string }>();
  const { email } = state || { email: ''};

  const { register, handleSubmit } = useForm<any>();
  const onSubmit = (variables: any) => resetPassword({ variables });
  
  const [resetPassword, { data, loading, error }] = useMutation<any>(mutation, {
    onError: () => {},
    onCompleted: data => {
      // goToCodeForm(); // redirect should be fine
    }
  });

  if (isLoggedIn || !email) {
    return (
      <Redirect to='/' />
    );
  }

  if (data?.reset_password && !error) {
    return (
      <Redirect to='/login' />
    );
  }

  return (
    <Container className='d-flex' style={{ height: '100vh', top: 0 }}>
      <div className='ml-auto mr-auto align-self-center'>
        <Card className='card-login'>
          <CardHeader>Reset Password</CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <h2>Reset Password for<br />{ email }</h2>
              <Input innerRef={register} type="hidden" name="email" id="email" value={email} />
              <FormGroup>
                <Label for="secret">Reset Code</Label>
                <Input innerRef={register} required type="number" name="secret" id="secret" autoComplete='secret' placeholder='Reset Code' />
              </FormGroup>
              <hr />
              <FormGroup>
                <Label for="password">New Password</Label>
                <Input innerRef={register} required type="password" name="password" id="password" autoComplete='password' placeholder='New Password' />
                {/* <Input innerRef={register} required type="password" name="confirm_password" id="confirm_password" placeholder='Confirm Password' /> */}
              </FormGroup>
              { loading ? 
                <Spinner /> :
                <Button type='submit'>
                  Reset!
                </Button>
              }
            </Form>
          </CardBody>
          { Boolean(error) &&
            <CardFooter>
              <CardText>{ error?.message }</CardText>
            </CardFooter>
          }
          { Boolean(data && !data.reset_password) &&
            <CardFooter>
              <CardText>Invalid Reset Code</CardText>
            </CardFooter>
          }
        </Card>
      </div>
    </Container>
  );
}

export default ResetPassword;