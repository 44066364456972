
import React, { ElementRef, forwardRef, ForwardRefRenderFunction, Ref, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import classNames from 'classnames';

export interface CreditCardDetails {
  number: string;
  month: string;
  year: string;
  cvc?: string;
}

interface CreditCardInputHandle {
  reset: () => void;
}

interface CreditCardInputProps {
  onCardDetails: (details: CreditCardDetails) => void;
  loading?: boolean;
}

type CreditCardInputType = ForwardRefRenderFunction<CreditCardInputHandle, CreditCardInputProps>;

const CreditCardInput: CreditCardInputType = ({ onCardDetails, loading }, ref) => {
  const { 
    meta: { error }, wrapperProps, getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps 
  } = usePaymentInputs();
  
  const [cardNumber, setCardNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');

  const reset = useCallback(() => {
    setCardNumber('');
    setExpiry('');
    setCvc('');
  }, [setCardNumber, setExpiry, setCvc]);

  useImperativeHandle(ref, () => ({ reset }), []);

  useEffect(() => {
    if (cardNumber && expiry && cvc && !error) {
      const [month, year] = expiry.split('/').map(s => s.trim());

      const details = {
        number: cardNumber.trim(),
        month,
        year,
        cvc
      };

      onCardDetails(details);
    }
  }, [cardNumber, expiry, cvc, onCardDetails, reset]);

  return (
    <PaymentInputsWrapper {...wrapperProps} className={classNames({ loading })}>
      <svg {...getCardImageProps({ images })} />
      <input {...getCardNumberProps(handle(setCardNumber))} value={cardNumber} disabled={loading} />
      <input {...getExpiryDateProps(handle(setExpiry))} value={expiry} disabled={loading} />
      <input {...getCVCProps(handle(setCvc))} value={cvc} disabled={loading} />
    </PaymentInputsWrapper>
  );
}

export default forwardRef(CreditCardInput);

function handle(set: Function) {
  return {
    onChange: (e: any) => {
      set(e.currentTarget.value);
    }
  }
}


