
import React from 'react';
import { Row, Col } from 'reactstrap';
import NeighborsTableCard from 'widgets/NeighborsTableCard';

const Neighbors = () => {

  return (
    <div className='content'>
      <Row>
        <Col>
          <NeighborsTableCard />
        </Col>
      </Row>
    </div>
  );
}

export default Neighbors;