
import React, { useMemo } from 'react';
import DataTable from 'components/Table/DataTable';
import { gql, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

const query = gql`
  query PastMeetings {
    past_meetings {
      meeting_id
      location
      date
      date_with_timestamp
      time
    }
  }
`;

export default function PastMeetingsTableCard() {
  const { data } = useQuery(query, {
    fetchPolicy: 'cache-and-network'
  });

  const columns = useMemo(() => [{
    Header: 'Meeting ID',
    accessor: 'meeting_id'
  }, {
    Header: 'Location',
    accessor: 'location'
  }, {
    Header: 'Date',
    accessor: 'date',
    Cell: ({ value, row }: { value: number, row: any }) => {
      let date = row?.original?.date_with_timestamp.split(' ')[0];

      try {
        date = format(new Date(row?.original?.date_with_timestamp), 'MMM do, yyyy');
      } catch (e) { }

      return (
        <Link to={`/meetings/${row?.original?.meeting_id}/${value}`}>
          { date }
        </Link>
      );
    }
  }], []);

  const meetings = useMemo(() => data?.past_meetings || [], [data]);

  return (
    <DataTable
      title='Past Meetings'
      data={meetings} 
      columns={columns} 
      initialSort='date'
      />
  );
}