
import { gql } from "@apollo/client";
import { client } from "./GraphQL";
import { getRefreshToken, getTokenExpiry, login, logout, REFRESH_BUFFER_MS } from "./AuthContext";

const mutation = gql`
  mutation RefreshTokens($refreshToken: String!) {
    refresh_token(refresh_token: $refreshToken) {
      token
      refresh_token
      user {
        username
        email
      }
    }
  }
`;

export default async function refreshTokens() {
  const shouldRefresh = getTokenExpiry().getTime() - REFRESH_BUFFER_MS < Date.now();

  // console.log('refresh_tokens', {
  //   shouldRefresh,
  //   now: Date.now(),
  //   lastRefresh: getLastRefresh(),
  //   diff: Date.now() - getLastRefresh(),
  //   ttx: (getTokenExpiry().getTime() - Date.now()) / 1000 + ' seconds',
  //   expiry: getTokenExpiry(),
  //   expired: isTokenExpired()
  // });

  if (shouldRefresh) {
    if (getRefreshToken()) {
      try {
        const { data } = await client.mutate({
          mutation,
          variables: {
            refreshToken: getRefreshToken()
          }
        });
  
        if (data?.refresh_token) {
          await login(data.refresh_token.token, data.refresh_token.refresh_token);
        } else {
          logout();
        }
      } catch(e) {
        console.error('refreshTokens', e);

        if (e.message === 'Invalid refresh token.') {
          console.log('Invalid refresh token, logging out.');
          logout();
        }
      }
    } else {
      logout();
    }
  }
}