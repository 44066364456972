
import React, { Fragment } from "react";
import { Switch, useLocation } from "react-router-dom";

// core components
import RTLNavbar from "components/Navbars/RTLNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import useBrandText from "layouts/useBrandText";
import usePerfectScrollbar from "layouts/usePerfectScrollbar";
import useToggleSidebar from "components/Sidebar/useToggleSidebar";

import routes, { getRoutes, filterRoutes } from "routes";

import logo from "assets/img/react-logo.png";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";

function RTL() {
  const location = useLocation();
  const mainPanelRef = React.useRef(null);
  usePerfectScrollbar(mainPanelRef.current);
  const [sidebarOpened, toggleSidebar] = useToggleSidebar();
  const brandText = useBrandText();

  return (
    <>
      <BackgroundColorContext.Consumer>
        {({ color, changeColor }) => (
          <Fragment>
            <div className="wrapper">
              <Sidebar
                routes={filterRoutes(routes, '/rtl')}
                rtlActive
                logo={{
                  outerLink: "https://www.creative-tim.com/",
                  text: "الإبداعية تيم",
                  imgSrc: logo,
                }}
                toggleSidebar={toggleSidebar}
              />
              <div className="main-panel" ref={mainPanelRef} data={color}>
                <RTLNavbar
                  brandText={brandText}
                  toggleSidebar={toggleSidebar}
                  sidebarOpened={sidebarOpened}
                />
                <Switch>{getRoutes(routes, '/rtl')}</Switch>
                {
                  // we don't want the Footer to be rendered on map page
                  location.pathname === "/admin/maps" ? null : <Footer fluid />
                }
              </div>
            </div>
            <FixedPlugin bgColor={color} handleBgClick={changeColor} />
          </Fragment>
        )}
      </BackgroundColorContext.Consumer>
    </>
  );
}

export default RTL;
