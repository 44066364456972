
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";
import LoginLayout from "layouts/Login/Login";
import LoggingInLayout from "layouts/Login/LoggingIn";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import { AuthProvider, useAuthStore } from "api/AuthContext";
import { GraphQlProvider } from "api/GraphQL";
import DebugView from "components/FixedPlugin/DebugView";

function Pages() {
  const { isLoggedIn, isTokenExpired } = useAuthStore();

  return (
    <Switch>
      <Route path="/login" render={(props) => <LoginLayout {...props} />} />

      { isLoggedIn ? (
        <Switch>
          {
            isTokenExpired && (
              <Route path='*' component={LoggingInLayout} />
            )
          }
          <Route path="/admin" render={(props) => <AdminLayout {...props} layout='/admin' />} />
          <Route path="/rtl" render={(props) => <RTLLayout {...props} />} />
          <Route path="/" render={(props) => <AdminLayout {...props} />} />
          <Redirect from="/" to='/dashboard' />
        </Switch>
      ) : (
        <Redirect path='*' to='/login' />
      )}
    </Switch>
  );
}

ReactDOM.render(
  <ThemeContextWrapper>
    <AuthProvider>
      <DebugView />
      <GraphQlProvider>
        <BackgroundColorWrapper>
          <BrowserRouter>
            <Pages />
          </BrowserRouter>
        </BackgroundColorWrapper>
      </GraphQlProvider>
    </AuthProvider>
  </ThemeContextWrapper>,
  document.getElementById("root")
);
