
import React, { useCallback, useMemo, useState } from 'react';
import { Alert, Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm } from "react-hook-form";
import MDEditor from '@uiw/react-md-editor';

export interface HnpEvent {
  uuid?: string;
  title?: string;
  description?: string;
  location?: string;
  url?: string;
  zoom_meeting_id?: string;
  zoom_meeting_password?: string;
  date: string;
  time: string;
  end_time: string;
  type: string;
  recur?: {
    week?: number;
    day?: string;
  }
}

const TIME_OFFSET = '-07';
function addTimeOffset(time?: string) {
  return time ? time + TIME_OFFSET : undefined
}

type UpdateEventCallback = (event: HnpEvent) => Promise<HnpEvent | void>;

interface UpdateEventFormProps {
  currentEvent?: HnpEvent;
  onUpdate: UpdateEventCallback;
}

function UpdateEventForm({ currentEvent, onUpdate }: UpdateEventFormProps) {
  const [isRecurring, setRecurring] = useState<boolean>(false);
  const { register, handleSubmit, errors, control, reset } = useForm<HnpEvent>({
    defaultValues: currentEvent
  });

  const onSubmit = useCallback(async (data: HnpEvent) => {
    const newEvent = await onUpdate(data);

    if (newEvent) {
      reset();
    }
  }, [onUpdate]);

  return (
    <Card>
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Input innerRef={register} type='hidden' name='uuid' />
          <FormGroup>
            <Label>Title</Label>
            <Input 
              innerRef={register({ required: 'Please enter event title.' })} 
              name='title' 
              placeholder='Title' 
              />
            <ErrorMessage as={Alert} color='danger' errors={errors} name='title' />
          </FormGroup>
          <FormGroup>
            <Label>Instructor</Label>
            <Input 
              innerRef={register({ required: 'Please enter event instructor.' })} 
              name='instructor' 
              placeholder='Instructor' 
              />
            <ErrorMessage as={Alert} color='danger' errors={errors} name='title' />
          </FormGroup>
          <FormGroup>
            <Label>Description</Label>
            {/* <Controller as={MDEditor} control={control} name='description' defaultValue='' /> */}
            <Controller
              name='description'
              control={control}
              defaultValue=''
              rules={{ required: true }}
              render={props =>
                <Row>
                  <Col xs={12} md={6}>
                    <MDEditor
                      preview='edit'
                      onChange={value => props.onChange(value)}
                      value={props.value}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <MDEditor.Markdown source={props.value} />
                  </Col>
                </Row>
              }
            />
            <ErrorMessage as={Alert} color='danger' errors={errors} name='description' />
          </FormGroup>
          <FormGroup>
            <Label>URL</Label>
            <Input innerRef={register} name='url' placeholder='URL' />
            <ErrorMessage as={Alert} color='danger' errors={errors} name='url' />
          </FormGroup>
          <hr />
          <FormGroup check>
            <Label check>
              <Input 
                style={{ opacity: 100, visibility: 'visible', position: 'relative' }} 
                type="checkbox" 
                checked={isRecurring} 
                onChange={() => setRecurring(r => !r)} 
                />
              {' '}
              Recurring Event
            </Label>
          </FormGroup>
          { Boolean(isRecurring) ? (
            <FormGroup>
              <Label>Recurring</Label>
              <Row>
                <Col sm={12} md={6}>
                  <Label>Week</Label>
                  <Input innerRef={register({required: 'Please select recurring week of the month.', valueAsNumber: true})} defaultValue={0} type='select' name='recur.week'>
                    <option value={0}>First</option>
                    <option value={1}>Second</option>
                    <option value={2}>Third</option>
                    <option value={3}>Fourth</option>
                    <option value={4}>Last</option>
                  </Input>
                </Col>
                <Col>
                  <Label>Day</Label>
                  <Input innerRef={register({required: 'Please select recurring day.'})} defaultValue='Monday' type='select' name='recur.day'>
                    <option value='Monday'>Monday</option>
                    <option value='Tuesday'>Tuesday</option>
                    <option value='Wednsday'>Wednsday</option>
                    <option value='Thursday'>Thursday</option>
                    <option value='Friday'>Friday</option>
                    <option value='Saturday'>Saturday</option>
                    <option value='Sunday'>Sunday</option>
                  </Input>
                </Col>
              </Row>
              <ErrorMessage errors={errors} name='recur.week' />
              <ErrorMessage errors={errors} name='recur.day' />
            </FormGroup>
          ) : (
            <FormGroup>
              <Label>Date</Label>
              <Input type='date' innerRef={register({ valueAsDate: true })} name='date' placeholder='Start Date' />
              <ErrorMessage as={Alert} color='danger' errors={errors} name='date' />
            </FormGroup>
          )}
          <FormGroup>
            <Label>Time</Label>
            <Input type='time' innerRef={register({ setValueAs: addTimeOffset })} name='time' placeholder='Start Time' />
            <ErrorMessage as={Alert} color='danger' errors={errors} name='time' />
          </FormGroup>
          <FormGroup>
            <Label>End Time</Label>
            <Input type='time' innerRef={register({ setValueAs: addTimeOffset })} name='end_time' placeholder='End Time' />
            <ErrorMessage as={Alert} color='danger' errors={errors} name='end_time' />
          </FormGroup>
          <hr />
          <FormGroup>
            <Label>Type</Label>
            <Input innerRef={register({required: 'Please select class type.'})} defaultValue='PUBLIC' type='select' name='type'>
              <option value='PUBLIC'>Public</option>
              <option value='MEMBERS'>Members</option>
            </Input>
            <ErrorMessage errors={errors} name='type' />
          </FormGroup>
          <hr />
          <Button type='submit' color='primary'>
            { currentEvent ? 'Update' : 'Add' } Event
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
}

export default UpdateEventForm;