
import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Control, FieldErrors, Controller } from 'react-hook-form';
import { FormGroup, Label, FormText } from 'reactstrap';
import Asterisk from './Asterisk';
import ZipsInput from './ZipsInput';

interface ControlledZipsInputProps {
  name: string;
  control: Control;
  defaultValue?: Array<string>;
  required?: boolean | string;
  errors?: FieldErrors;
}

function ControlledZipsInput({ name, control, defaultValue, required, errors }: ControlledZipsInputProps) {

  return (
    <FormGroup>
      <Label>Zip Codes{ required && <Asterisk /> }</Label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ required }}
        render={({ value, onChange }) => (
          <ZipsInput onChange={onChange} zips={value} />
        )} />
      <ErrorMessage as={FormText} color='danger' name={name} errors={errors} />
    </FormGroup>
  );
}

export default ControlledZipsInput;