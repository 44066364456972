
import React, { useCallback, useState } from 'react';
import { Tooltip } from 'reactstrap';
import ErrorBoundary from './ErrorBoundary';

function CustomTooltip({ text, target }: { text?: string, target: string }) {
  const [isOpen, setOpen] = useState(false);
  const toggle = useCallback(() => setOpen(open => !open), []);

  return (
    <ErrorBoundary>
      <Tooltip trigger='hover focus' target={target} isOpen={isOpen} toggle={toggle}>
        { text }
      </Tooltip>
    </ErrorBoundary>
  )
}

export default CustomTooltip;