
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import getSignedS3UploadUrl from "api/files/getSignedS3UploadUrl";
import uploadFile, { OnProgressHandler } from "api/files/upoadFile";

// Select file
// Upload file
// File is uploaded
// Display file url && allow removal

export interface FileUploadProps {
  uuid?: string;
  onUUID: (uuid?: string) => void;
  onProgress?: OnProgressHandler;
}

export default function FileUploadInput({ uuid, onUUID, onProgress }: FileUploadProps) {
  const [files, setFiles] = useState<FileList | null>();

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => 
    setFiles(e.currentTarget.files), 
    [setFiles]
  );

  useEffect(() => {
    if (files && files.length) {
      (async () => {
        try {
          const file = files[0];
          const { signedRequest, file: hnpFile } = await getSignedS3UploadUrl({
            name: file.name,
            type: file.type,
            size: file.size
          });
  
          await uploadFile(
            signedRequest, 
            file, 
            onProgress
          );

          setFiles(undefined);
          onUUID(hnpFile?.uuid);
        } catch(e) {
          console.error('FileUploadInput useEffect', e);
        }
      })();
    } else {

    }
  }, [files, onUUID, onProgress]);

  if (uuid) {
    return (
      <input 
        key={uuid}
        type='text' 
        value={uuid} 
        contentEditable={false} 
        readOnly
        />
    );
  }
 
  return (
    <input 
      type='file' 
      name="file" 
      id="file" 
      onChange={onChange}
      />
  );
}