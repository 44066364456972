
import React, { useState } from 'react'
import { SkyLightStateless } from 'react-skylight';
import { confirmable, createConfirmation } from 'react-confirm';
import { Card, Button, CardHeader, CardTitle, CardBody, Input, ButtonGroup, CardFooter, CardText } from 'reactstrap';

export interface ConfirmProps {
  show: boolean;
  proceed: (note?: string) => void;
  dismiss: VoidFunction;
  cancel: VoidFunction;
  message?: string;
  title?: string;
  hasNote?: boolean;
}

export const Confirm = confirmable(({show, proceed, dismiss, cancel, message, title, hasNote}: ConfirmProps) => {
  const [note, setNote] = useState('');

  return (
    <SkyLightStateless
      onCloseClicked={dismiss}
      onOverlayClicked={dismiss}
      isVisible={show}
      dialogStyles={{minHeight: 10, backgroundColor: 'transparent', boxShadow: 'none'}}
      closeButtonStyle={{display: 'none'}}
      >
      <style>{'.mb0 { margin-bottom: 0; }'}</style>
      <Card>
        <CardBody>
          <CardTitle tag='h4'>Are you sure?</CardTitle>
          <CardText>{ message }</CardText>
          { hasNote &&
            <Input
              onChange={e => setNote(e.currentTarget.value)}
              value={note}
              />
          }
        </CardBody>
        <CardFooter className='text-right'>
          <ButtonGroup>
            <Button size='sm' onClick={cancel}>Cancel</Button>
            <Button
              disabled={hasNote && (!note || !note.length)}
              size='sm'
              color='danger'
              onClick={() => proceed(note)}
              >
                { title || 'Ok' }
            </Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
    </SkyLightStateless>
  );
});

const callConfirm = createConfirmation(Confirm);


export async function confirm(message: string, title?: string): Promise<boolean>;
export async function confirm(message: string, title?: string, hasNote?: boolean): Promise<boolean | string>;
export async function confirm(message: string, title?: string, hasNote?: boolean): Promise<boolean | string> {
  try {
    const response = await callConfirm({ message, title, hasNote });

    if (hasNote && response) {
      return response;
    }

    return true;
  } catch(e) {
    return false;
  }
}

export const confirmWithNote = (message: string, title?: string) => {
  return confirm(message, title, true);
}
