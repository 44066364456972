
import { gql, useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import UpdateEventForm, { HnpEvent } from 'widgets/UpdateEventForm';

const mutation = gql`
  mutation AddEvent($event: EventInput!) {
    add_event(event: $event) {
      title
      description
    }
  }
`;

const AddEvent = () => {
  const [addEvent] = useMutation(mutation, {
    errorPolicy: 'none'
  });

  const submit = useCallback(async (event: HnpEvent) => {
    delete event.uuid;
    const result = await addEvent({ variables: { event }});
    console.log({ event, result });
    return result?.data?.add_event;
  }, [addEvent]);

  return (
    <div className='content'>
      <Row>
        <Col>
          <UpdateEventForm onUpdate={submit} />
        </Col>
      </Row>
    </div>
  );
}

export default AddEvent;