
import React from 'react';
import { Row, Col, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom';

const Test = () => {

  return (
    <div className='content'>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Link to='/dashboard'>View User Dashboard</Link>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Test;