
import React from 'react';
import { Container, Card, Button, CardBody, CardHeader, CardText, Spinner, CardFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import { useAuthStore } from 'api/AuthContext';
import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';

const mutation = gql`
  mutation RequestReset($email: String!) {
    request_reset(email: $email)
  }
`;

const RequestReset = () => {
  const { isLoggedIn } = useAuthStore();

  const { register, handleSubmit, watch } = useForm<LoginValues>();
  const email = watch('email');
  const onSubmit = (variables: any) => requestReset({ variables });
  
  const [requestReset, { data, loading, error }] = useMutation<any>(mutation, {
    onError: () => {},
    onCompleted: data => {
      // goToCodeForm(); // redirect should be fine
    }
  });

  if (isLoggedIn) {
    return (
      <Redirect to='/' />
    );
  }

  if (data?.request_reset && !error) {
    return (
      <Redirect to={{
        pathname: '/login/reset',
        state: { email }
      }} />
    );
  }

  return (
    <Container className='d-flex' style={{ height: '100vh', top: 0 }}>
      <div className='ml-auto mr-auto align-self-center'>
        <Card className='card-login'>
          <CardHeader>Reset Password</CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Label for="email">Email Address</Label>
                <Input innerRef={register} type="text" name="email" id="email" autoComplete='email' required />
              </FormGroup>
              { loading ? 
                <Spinner /> :
                <Button type='submit'>
                  Request Password Reset
                </Button>
              }
            </Form>
          </CardBody>
          { Boolean(error) &&
            <CardFooter>
              <CardText>{ error?.message }</CardText>
            </CardFooter>
          }
        </Card>
      </div>
    </Container>
  );
}

export default RequestReset;