
import React, { Component, ErrorInfo } from 'react';

export type OnErrorHandler = (error: Error, info: ErrorInfo) => void;

interface ErrorBoundaryProps {
  log?: boolean | OnErrorHandler;
}

interface ErrorBoundaryState { 
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { 
      hasError: false 
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { 
      hasError: true 
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (this.props.log) {
      console.error(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;