
import React, { useState, useCallback } from 'react';
import { gql, useQuery } from '@apollo/client';
import Autocomplete from 'react-autocomplete';
import classNames from 'classnames';
import fuzzysearch from 'fuzzysearch';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';

export interface Category {
  name: string;
  slug: string;
  uuid: string;
}

type OnSelectHandler = (category?: Category) => void;

interface CategorySelectProps {
  category?: Category;
  onSelect: OnSelectHandler;
}

const query = gql`
  query GetCategories {
    categories {
      name
      slug
      uuid
    }
  }
`;

type CategoryResponse = {
  categories: Array<Category>
}

const styles = {
  item: { 
    paddingLeft: '.1em', 
    paddingRight: '.1em', 
    textAlign: 'left' 
  },
  menu: {
    zIndex: 1,
    borderRadius: '3px',
    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '2px 0',
    fontSize: '90%',
    position: 'fixed',
    overflow: 'auto',
    maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
  }
};

const CategorySelect = ({ category, onSelect }: CategorySelectProps) => {
  const [value, setValue] = useState(category ? category.name : '');
  const { loading, error, data, refetch } = useQuery<CategoryResponse>(query);

  const onChangeText = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const onSelectCategory = useCallback((name, cat: Category) => {
    setValue(name);
    if (cat.uuid !== category?.uuid) {
      onSelect(cat);
    }
  }, [category, onSelect]);

  const shouldItemRender = useCallback((category, value) => {
    return fuzzysearch(value.toLowerCase(), category.name.toLowerCase());
  }, []);

  return (
    <Autocomplete
      value={value}
      getItemValue={item => item.name}
      items={data?.categories || []}
      onChange={onChangeText}
      onSelect={onSelectCategory}
      shouldItemRender={shouldItemRender}
      menuStyle={{
        zIndex: 1,
        borderRadius: '3px',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
        background: 'rgba(255, 255, 255, 0.9)',
        padding: '2px 0',
        fontSize: '90%',
        position: 'fixed',
        overflow: 'auto',
        maxHeight: '50%'
      }}
      autoHighlight
      selectOnBlur
      wrapperStyle={{ display: 'unset' }}
      renderItem={(item, isHighlighted) => (
        <div key={item.uuid} style={{
          paddingLeft: '.1em', 
          paddingRight: '.1em', 
          textAlign: 'left' , 
          background: isHighlighted ? 'lightgray' : 'white'
          }}>
          { item.name }
        </div>
      )}
      renderInput={props => (
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Category</InputGroupText>
          </InputGroupAddon>
          <input 
            className='form-control' 
            placeholder={loading ? 'Loading Categories...' :'Coaches, Realtors, Dentists, Restaurants...'}
            {...props}
            />
        </InputGroup>
      )}
      />
  );
}

export default CategorySelect;