
import React, { useCallback, useEffect } from 'react';
import { Alert, Button, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from "react-hook-form";
import HookFormImageFileInput from 'components/custom/HookFormImageInput';

export interface Listing {
  [index: string]: string | undefined;
  name?: string;
  blurb?: string;
  description?: string;
  website?: string;
  email?: string;
  phone?: string;
  logo_uuid?: string;
  happy_reward?: string;
}

type UpdateListingCallback = (listing: Listing) => void;

interface UpdateListingsFormProps {
  currentListing: Listing;
  onUpdate: UpdateListingCallback;
}

function useDontCloseYet(dontClose?: string | boolean) {
  // Later versions of some browsers (Chrome...) will ignore your custom text.
  useEffect(() => {
    if (dontClose) {
      const beforeUnload = (e: BeforeUnloadEvent) => {
        e.preventDefault();

        e.returnValue = typeof dontClose === 'string' ? 
          dontClose : 
          'Are you sure you wish to close?';
      };

      window.addEventListener('beforeunload', beforeUnload);

      return () => {
        window.removeEventListener('beforeunload', beforeUnload);
      }
    }
  }, [dontClose]);
}

function UpdateListingsForm({ currentListing, onUpdate }: UpdateListingsFormProps) {
  const { register, handleSubmit, control, errors, formState } = useForm<Listing>({
    defaultValues: currentListing
  });

  const { isDirty, dirtyFields } = formState;

  useDontCloseYet(isDirty && Object.keys(dirtyFields).length ? 'You have unsaved input...' : false);

  const onSubmit = useCallback((data: Listing) => onUpdate(data), [onUpdate]);

  return (
    <Card>
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Name</Label>
            <Input 
              innerRef={register({ required: 'Please enter your company name.' })} 
              name='name' 
              placeholder='Name' 
              />
            <ErrorMessage errors={errors} name='name' />
          </FormGroup>
          <FormGroup>
            <Label>Blurb</Label>
            <Input innerRef={register} name='blurb' placeholder='Blurb' />
            <ErrorMessage errors={errors} name='blurb' />
          </FormGroup>
          <FormGroup>
            <Label>Description</Label>
            <Input 
              innerRef={register({ required: 'Please enter a description.' })} 
              type='textarea' 
              name='description' 
              placeholder='Description' 
              />
            <ErrorMessage as={Alert} color='danger' errors={errors} name='description' />
          </FormGroup>
          <FormGroup>
            <Label>Website</Label>
            <Input innerRef={register} name='website' placeholder='Website' />
            <ErrorMessage errors={errors} name='website' />
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input innerRef={register} rules='isEmail' name='email' placeholder='Email' />
            <ErrorMessage errors={errors} name='email' />
          </FormGroup>
          <FormGroup>
            <Label>Phone</Label>
            <Input innerRef={register} name='phone' placeholder='Phone' />
            <ErrorMessage errors={errors} name='phone' />
          </FormGroup>
          <HookFormImageFileInput 
            control={control} 
            name='logo_uuid' 
            label='Logo'
            />
          <ErrorMessage errors={errors} name='logo_uuid' />
          <FormGroup>
            <Label>Happy Reward</Label>
            <Input innerRef={register} name='happy_reward' placeholder='Happy Reward' />
            <ErrorMessage errors={errors} name='happy_reward' />
          </FormGroup>
          <Button type='submit' color='primary'>Update</Button>
        </Form>
      </CardBody>
    </Card>
  );
}

export default UpdateListingsForm;