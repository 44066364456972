
import { isDebug } from "./AuthContext";
import Stripe from 'stripe';
import { CreditCardDetails } from "components/custom/CreditCardInput";

const STRIPE_PUBLIC_KEY_PRODUCTION = 'pk_live_6RBkVlHP5MIW0Z8ol4qA9Ubb';
const STRIPE_PUBLIC_KEY_DEVELOPMENT = 'pk_test_wVcBMBlthusazJyMZPztJ6pz';

const STRIPE_TOKENS_URL = 'https://api.stripe.com/v1/tokens';

export function getStripePublicKey() {
  return isDebug() ? STRIPE_PUBLIC_KEY_DEVELOPMENT : STRIPE_PUBLIC_KEY_PRODUCTION;
}
  
export default async function createStripeCardToken({ number, month, year, cvc }: CreditCardDetails) {
  const body = toShallowFormBody({
    'card[number]': number,
    'card[exp_month]': month,
    'card[exp_year]': year,
    ...(cvc ? {'card[cvc]': cvc} : {})
  });

  const response = await fetch(STRIPE_TOKENS_URL, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'Authorization': `Bearer ${getStripePublicKey()}`
    },
    method: 'POST',
    body
  });

  const token: Stripe.Token = await response.json();

  return token;
}

function toShallowFormBody(body: any) {
  return Object.keys(body).map(key => 
    encodeURIComponent(key) + '=' + encodeURIComponent(body[key])
  ).join('&');
}