
import React, { useCallback, useMemo } from 'react';
import { Alert, Button, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from "react-hook-form";
import { gql, useQuery } from '@apollo/client';

export interface Cohost {
  uuid?: string;
  meeting_id?: string;
  meeting_location?: string;
  name?: string;
  email?: string;
  phone?: string;
  company?: string;
  type?: string;
  website?: string;
  mentor_uuid?: string;
  meetings_captained?: Array<string>;
}

type UpdateCohostCallback = (cohost: Cohost) => Promise<Cohost>;

interface UpdateCohostFormProps {
  currentCohost?: Cohost;
  onUpdate: UpdateCohostCallback;
}

type Meetings = Array<{
  meeting_id: string;
  location: string;
}>;

type Mentors = Array<{
  uuid: string;
  name: string;
}>;

const meetings_query = gql`
  query MeetingsAndMentors {
    meetings {
      location
      meeting_id
    }
    mentors {
      uuid
      name
    }
  }
`;

function UpdateCohostForm({ currentCohost, onUpdate }: UpdateCohostFormProps) {
  const { data } = useQuery<{ meetings: Meetings, mentors: Mentors }>(meetings_query);
  const { register, handleSubmit, errors, reset, watch } = useForm<Cohost>({
    defaultValues: currentCohost
  });

  const type = watch('type');

  const meetingOptions = useMemo(() => 
    data?.meetings?.length ? data.meetings.map(meeting => (
      <option key={meeting.meeting_id} value={meeting.meeting_id}>
        { meeting.location }
      </option>
    )) : [], [data]);

  const mentorOptions = useMemo(() => 
    data?.mentors?.length ? data.mentors.map(mentor => (
      <option key={mentor.uuid} value={mentor.uuid}>
        { mentor.name }
      </option>
    )) : [], [data]);

  const onSubmit = useCallback(async (data: Cohost) => {
    const newCohost = await onUpdate(data);

    if (newCohost) {
      reset();
    }
  }, [onUpdate]);

  return (
    <Card>
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Input innerRef={register} type='hidden' name='uuid' />
          <FormGroup>
            <Label>Meeting</Label>
            <Input innerRef={register({required: 'Please select a meeting.'})} type='select' name='meeting_id'>
              { meetingOptions }
            </Input>
            <ErrorMessage errors={errors} name='meeting_id' />
          </FormGroup>
          <FormGroup>
            <Label>Name</Label>
            <Input 
              innerRef={register({ required: 'Please enter co-host company name.' })} 
              name='name' 
              placeholder='Name' 
              />
            <ErrorMessage as={Alert} color='danger' errors={errors} name='name' />
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input innerRef={register} name='email' placeholder='Email' />
            <ErrorMessage as={Alert} color='danger' errors={errors} name='email' />
          </FormGroup>
          <FormGroup>
            <Label>Phone Number</Label>
            <Input innerRef={register} name='phone' placeholder='Phone Number' />
            <ErrorMessage as={Alert} color='danger' errors={errors} name='phone' />
          </FormGroup>
          <FormGroup>
            <Label>Company</Label>
            <Input innerRef={register} name='company' placeholder='Company Name' />
            <ErrorMessage as={Alert} color='danger' errors={errors} name='company' />
          </FormGroup>
          <FormGroup>
            <Label>Website</Label>
            <Input innerRef={register} name='website' placeholder='Website' />
            <ErrorMessage as={Alert} color='danger' errors={errors} name='website' />
          </FormGroup>
          <FormGroup>
            <Label>Type</Label>
            <Input innerRef={register({required: 'Please select host type.'})} defaultValue='COHOST' type='select' name='type'>
              <option value='COHOST'>Co-Host</option>
              <option value='CAPTAIN'>Captain</option>
              <option value='MENTOR'>Mentor</option>
              <option value='TRAINER'>Trainer</option>
            </Input>
            <ErrorMessage errors={errors} name='type' />
          </FormGroup>
          { type === 'CAPTAIN' && 
            <FormGroup>
              <Label>Mentor</Label>
              <Input innerRef={register({ setValueAs: (value) => value || null })} type='select' name='mentor_uuid'>
                <option></option>
                { mentorOptions }
              </Input>
              <ErrorMessage errors={errors} name='mentor_uuid' />
            </FormGroup>
          }
          { type === 'CAPTAIN' && 
            <FormGroup>
              <Label>Meetings Captained</Label>
              <p>---</p>
              <ErrorMessage errors={errors} name='meetings_captained' />
            </FormGroup>
          }
          <Button type='submit' color='primary'>
            { currentCohost ? 'Update' : 'Add' } Co-Host
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
}

export default UpdateCohostForm;