
import React, { useMemo } from 'react';
import DataTable from 'components/Table/DataTable';
import { confirm } from 'components/custom/Confirm';
import { gql, useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { RowAction } from 'components/Table/RowActionItem';
import { Popover, PopoverBody } from 'reactstrap';

const query = gql`
  query Cohosts {
    cohosts {
      uuid
      type
      name
      email
      phone
      company
      website
      meeting_id
      meeting_location
      mentor_uuid
      started_at
      ended_at
    }
  }
`;

const retire_cohost_mutation = gql`
  mutation RetireCohost($uuid: String!) {
    retire_cohost(uuid: $uuid) {
      uuid
      name
      meeting_id
      meeting_location
      started_at
      ended_at
    }
  }
`;

interface Props {
  onSelect?: (cohost: any) => void;
  onUpdating?: (cohost: any) => void;
}

export default function CohostsTableCard({ onSelect, onUpdating }: Props) {
  const { data } = useQuery(query);
  const [retireCohost] = useMutation(retire_cohost_mutation);

  const columns = useMemo(() => [{
    Header: 'Name',
    accessor: 'name'
  }, {
    Header: 'Email',
    accessor: 'email',
    Cell: (props: any) => <a href={`mailto:${props.value}`}>{ props.value }</a>
  }, {
    Header: 'Phone',
    accessor: 'phone',
    Cell: (props: any) => <a href={`tel:${props.value}`}>{ props.value }</a>
  }, {
    Header: 'Meeting',
    accessor: 'meeting_location'
  }, {
    Header: 'Started',
    accessor: 'started_at',
    Cell: ({ value }: { value?: number }) => value ? format(value, 'MMM do, yyyy') : ''
  }, {
    Header: 'Ended',
    accessor: 'ended_at',
    Cell: ({ value }: { value?: number }) => value ? format(value, 'MMM do, yyyy') : ''
  }], []);

  const actions = useMemo<Array<RowAction>>(() => [{
    name: 'edit-cohost',
    icon: 'icon-pencil',
    color: 'warning',
    title: 'Edit Co-Host',
    shouldShow: (_, row) => Boolean(onUpdating),
    onAction: async (_, item) => { 
      onUpdating && onUpdating(item);
    }
  }, {
    name: 'retire-cohost',
    icon: 'icon-simple-remove',
    color: 'danger',
    title: 'Retire Co-Host',
    shouldShow: (_, row) => !Boolean(row.original.ended_at),
    onAction: async (_, item) => { 
      const confirmed = await confirm(
        `Are you sure want to retire ${item.name} as a Co-Host?`, 
        'Retire Co-Host'
      );

      if (confirmed) {
        const { data } = await retireCohost({
          variables: { 
            uuid: item.uuid 
          }
        });

        if (data.retire_cohost) {
          alert(`Co-Host: ${item.name} retired.`);
        }
      }
    }
  }], [onSelect]);

  const cohosts = useMemo(() => data?.cohosts || [], [data]);

  return (
    <DataTable
      title='Cohosts'
      data={cohosts} 
      columns={columns} 
      actions={actions}
      initialSort='started_at'
      />
  );
}