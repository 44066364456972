
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import routes from 'routes';


export default function useBrandText() {
  const location = useLocation();

  const text = useMemo(() => {
    for (let i = 0; i < routes.length; i++) {
      const route = routes[i];
      const path = (route.layout === '/' ? '' : route.layout) + route.path;
      
      if (location.pathname.indexOf(path) !== -1) {
        return route.name;
      }
    }

    return '---';
  }, [location]);

  return text;
}