
import CustomTooltip from "components/custom/TooltipCustom";
import React, { useMemo } from "react";
import { Button } from "reactstrap";

export interface BaseRowAction {
  icon?: string;
  title?: string;
  alt?: string;
  name: string;
  color?: string;
  onAction: (name: string, item: any) => void;
  shouldShow?: (name: string, row: any) => boolean;
}

export interface IconRowAction extends BaseRowAction {
  icon: string;
  alt: string;
}

export interface TitleRowAction extends BaseRowAction {
  title: string;
}

export type RowAction = IconRowAction | TitleRowAction;

function RowActionItem({ action, row }: { action: RowAction, row: any, color?: string }) {
  const { icon, title, alt, name, color = 'primary', onAction } = action;
  const id = useMemo(() => `tt_${row.id}_${name}`, [name, row]);

  return (
    <div>
      <div id={id} style={{ padding: '.2em', display: 'inline-block' }}>
        { icon ? (
          <Button title={alt || title} onClick={() => onAction(name, row.original)} color={color} className=' btn-icon btn-round' size='sm'>
            <i className={`tim-icons ${icon}`} />
          </Button>
        ) : (
          <Button title={alt || title} onClick={() => onAction(name, row.original)} color={color} className='btn-round' size='sm'>
            { title }
          </Button>
        )}
      </div>
      <CustomTooltip 
        target={id}
        text={title || alt}
        />
    </div>
  );
}

export default RowActionItem;