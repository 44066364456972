
import { gql, useQuery } from "@apollo/client";

const query = gql`
  query GetFile($file_uuid: String!) {
    get_file(file_uuid: $file_uuid) {
      uuid
      url
      key
      size
      content_type
    }
  }
`;

export interface HnpFile {
  uuid: string;
  url: string;
  key: string;
  size: number;
  content_type: string;
}

interface GetFileResult {
  get_file: HnpFile
}

export default function useFile(file_uuid: string = '') {
  const { data } = useQuery<GetFileResult>(query, {
    variables: { file_uuid }
  });

  return data?.get_file;
}