
import React from 'react';
import TagsInput, { RenderInputProps, RenderTagProps } from 'react-tagsinput';
import { Button, ButtonGroup, FormGroup, Input } from 'reactstrap';

interface ZipsInputProps {
  zips?: Array<string>;
  onChange: (zips: Array<string>) => void;
}

function ZipsInput({ zips = [], onChange }: ZipsInputProps) {

  return (
    <TagsInput
      value={zips}
      onChange={onChange}
      renderInput={renderInput}
      renderTag={renderTag}
      inputProps={{ placeholder: 'Add a Zip Code', autoComplete: 'new-password' }}
      validationRegex={/\b\d{5}\b/}
      onlyUnique
      addOnBlur
      />
  );
}

export default ZipsInput;

function renderInput (props: RenderInputProps) {
  const { onChange, value, addTag, ...other } = props;
  const zip = value.slice(0, 5);

  return (
    <FormGroup>
      <Input {...other} onChange={onChange} value={zip} />
    </FormGroup>
  )
}

function renderTag (props: RenderTagProps) {
  const { tag, key, disabled, onRemove, getTagDisplayValue, classNameRemove, ...other } = props;

  return (
    <ButtonGroup key={key} size='sm' style={{ marginRight: '.5em', marginBottom: '.5em' }}>
      <Button {...other} color='light' key={key} disabled>
        { getTagDisplayValue(tag) }
      </Button>
      <Button color='danger' onClick={() => disabled || onRemove(key)}>x</Button>
    </ButtonGroup>
  );
}