
import Fullscreen from "layouts/Fullscreen";
import React from "react";

function Login() {
  return (
    <Fullscreen 
      layout='/login' 
      redirect='/login' 
      />
  );
}

export default Login;
