
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

let ps: PerfectScrollbar;

export default function usePerfectScrollbar(mainPanelRef?: Element) {
  const location = useLocation();

  useEffect(() => {
    if (mainPanelRef) {
      if (navigator.platform.indexOf("Win") > -1) {
        document.documentElement.className += " perfect-scrollbar-on";
        document.documentElement.classList.remove("perfect-scrollbar-off");
  
        ps = new PerfectScrollbar(mainPanelRef, {
          suppressScrollX: true,
        });
  
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
  
      return function cleanup() {
        if (navigator.platform.indexOf("Win") > -1) {
          ps.destroy();
  
          document.documentElement.classList.add("perfect-scrollbar-off");
          document.documentElement.classList.remove("perfect-scrollbar-on");
        }
      }
    }
  }, [mainPanelRef]);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }

    document.documentElement.scrollTop = 0;

    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }

    if (mainPanelRef) {
      mainPanelRef.scrollTop = 0;
    }
  }, [mainPanelRef, location]);
}