
import React, { useMemo } from 'react';
import DataTable from 'components/Table/DataTable';
import { gql, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import ReactMarkdown from 'react-markdown'
import { Row, Col, Card } from 'reactstrap';
import { useParams } from 'react-router-dom';

const query = gql`
  query GetEvent($uuid: ID!) {
    event(uuid: $uuid) {
      title
      instructor
      description
      url
      start
    }
  }
`;

const EventPage = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { data } = useQuery(query, {
    variables: { uuid }
  });

  const event = data?.event;

  return (
    <div className='content'>
      <Row>
        <Col>
          <Card body>
            <h3>{ event?.title }</h3>
            <h4>{ event?.instructor }</h4>
            <hr />
            { Boolean(event?.start) && (
              <p>Time: { format(new Date(event?.start), 'EEE MMM do, yyyy h:mma') }</p>
            )}
            <hr />
            <ReactMarkdown>{ event?.description }</ReactMarkdown>
            { Boolean(event?.url) && (
              <>
                <hr />
                <a href={event?.url} target='_blank' rel='noreferrer noopener'>Register</a>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default EventPage;