
import React, { ComponentClass, FunctionComponent } from "react";
import { Route } from "react-router-dom";
import Dashboard from "views/Dashboard";
import Test from "views/Test";
import Login from "views/Login/Login";
import Onboard from "views/Login/Onboard";
import EditListing from "views/EditListing";
import CoHosts from "views/Admin/CoHosts";
import { User, UserRole } from "api/useUser";
import RegisterBusiness from "views/Admin/RegisterBusiness";
import ProcessPendingBusiness from "views/Admin/ProcessPendingBusiness";
import MeetingParticipants from "views/MeetingParticipants";
import PastMeetings from "views/PastMeetings";
import Neighbors from "views/Neighbors";
import SearchListings from "views/SearchListings";
import Businesses from "views/Admin/Businesses";
import Listing from "views/Listing";
import Events from "views/Events";
import EventPage from "views/Event";
import AddEvent from "views/AddEvent";
import EditEvent from "views/EditEvent";
import ResetPassword from "views/Login/ResetPassword";
import RequestReset from "views/Login/RequestReset";

type PageRoutePath = string | Array<string> | RegExp;

interface PageRoute {
  path: PageRoutePath;
  name: string;
  rtlName?: string;
  icon: string;
  component: ComponentClass | FunctionComponent;
  layout: string;
  inexact?: boolean;
  roles?: Array<UserRole>;
}

type PageRoutes = Array<PageRoute>;

const routes: PageRoutes = [
  {
    path: "/",
    name: "Login",
    rtlName: "اختبار",
    icon: "tim-icons icon-lock-circle",
    component: Login,
    layout: "/login",
  },
  {
    path: "/request-reset",
    name: "Request Reset",
    rtlName: "اختبار",
    icon: "tim-icons icon-lock-circle",
    component: RequestReset,
    layout: "/login",
  },
  {
    path: "/reset",
    name: "Reset Password",
    rtlName: "اختبار",
    icon: "tim-icons icon-lock-circle",
    component: ResetPassword,
    layout: "/login",
  },
  {
    path: "/onboard/:magic_code",
    name: "Onboarding",
    rtlName: "اختبار",
    icon: "tim-icons icon-lock-circle",
    component: Onboard,
    layout: "/login"
  },
  {
    path: "/listing/:uuid",
    name: "Listing",
    rtlName: "اختبار",
    icon: "tim-icons icon-lock-circle",
    component: Listing,
    layout: "/"
  },
  {
    path: "/dashboard",
    name: "Admin Dashboard",
    rtlName: "اختبار",
    icon: "tim-icons icon-palette",
    component: Test,
    layout: "/admin",
    roles: ['superadmin', 'admin', 'manager', 'sales']
  },
  {
    path: "/businesses",
    name: "Businesses",
    icon: "tim-icons icon-palette",
    component: Businesses,
    layout: "/admin",
    roles: ['superadmin', 'admin', 'manager', 'sales']
  },
  {
    path: "/register",
    name: "Register Business",
    icon: "tim-icons icon-palette",
    component: RegisterBusiness,
    layout: "/admin",
    roles: ['superadmin', 'admin', 'manager', 'sales']
  },
  {
    path: "/register/payment/:uuid",
    name: "Register Business (Payment)",
    icon: "tim-icons icon-palette",
    component: ProcessPendingBusiness,
    layout: "/admin",
    roles: ['superadmin', 'admin', 'manager', 'sales']
  },
  {
    path: "/cohosts",
    name: "Co-Hosts",
    rtlName: "اختبار",
    icon: "tim-icons icon-palette",
    component: CoHosts,
    layout: "/admin",
    roles: ['superadmin', 'admin', 'manager']
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/",
  },
  {
    path: "/events",
    name: "Events",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-calendar-60",
    component: Events,
    layout: "/",
  },
  {
    path: "/add-event",
    name: "Add Event",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-calendar-60",
    component: AddEvent,
    layout: "/admin",
    roles: ['superadmin', 'admin', 'manager']
  },
  {
    path: "/events/:uuid",
    name: "Event",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: EventPage,
    layout: "/",
  },
  {
    path: "/events/:uuid/edit",
    name: "Edit Event",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: EditEvent,
    layout: "/",
  },
  {
    path: "/search",
    name: "Happy Business Search",
    icon: "tim-icons icon-zoom-split",
    component: SearchListings,
    layout: "/"
  },
  {
    path: "/search/:slug",
    name: "Happy Business Search",
    icon: "tim-icons icon-zoom-split",
    component: SearchListings,
    layout: "/"
  },
  {
    path: "/listings/:uuid",
    name: "Listing",
    rtlName: "اختبار",
    icon: "tim-icons icon-lock-circle",
    component: Listing,
    layout: "/"
  },
  {
    path: "/listing",
    name: "My Listing",
    icon: "tim-icons icon-single-copy-04",
    component: EditListing,
    layout: "/"
  },
  {
    path: "/meetings",
    name: "Past Meetings",
    rtlName: "اختبار",
    icon: "tim-icons icon-lock-circle",
    component: PastMeetings,
    layout: "/"
  },
  {
    path: "/neighbors",
    name: "Neighbors",
    rtlName: "اختبار",
    icon: "tim-icons icon-lock-circle",
    component: Neighbors,
    layout: "/"
  },
  {
    path: "/meetings/:meeting_id/:date",
    name: "Participants",
    rtlName: "اختبار",
    icon: "tim-icons icon-lock-circle",
    component: MeetingParticipants,
    layout: "/"
  }
];

export default routes;

function joinPath(layout: string, path: PageRoutePath) {
  return (layout === '/' ? '' : layout) + path;
}

const slashRegEx = new RegExp('/', 'g');
function hasMultipleSlashes(text: string) {
  return (text.match(slashRegEx) || []).length > 1;
}

function isNotTopLevel(path: PageRoutePath) {
  if (typeof path === 'string') {
    return hasMultipleSlashes(path);
  }

  return true;
}

export const filterRoutes = (routes: PageRoutes, layout = '', user?: User, topLevelOnly?: boolean) => {
  return routes.filter(props => {
    if (topLevelOnly && isNotTopLevel(props.path)) {
      return false;
    }

    if (props.layout === layout) {
      let validRole = true;
  
      if (props.roles) {
        if (user?.role) {
          validRole = props.roles.indexOf(user.role) > -1;
        } else {
          validRole = false;
        }
      } else {
        console.warn(`NO ROLES FOR ${joinPath(layout, props.path)}`, 'All routes should have valid roles explicitly listed.')
      }

      return validRole;
    }

    return false;
  });
}

export const getRoutes = (routes: PageRoutes, layout = '', user?: User) => {
  return filterRoutes(routes, layout, user)
    .map((props, key) => {
      const path = (props.layout === '/' ? '' : props.layout) + props.path;

      return (
        <Route
          path={path}
          component={props.component}
          key={key}
          exact={!props.inexact}
        />
      );
  });
};
