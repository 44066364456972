
import useFile, { HnpFile } from 'api/files/useFile';
import React, { ReactNode } from 'react';
import { Alert, Card, CardTitle } from 'reactstrap';
import FileUploadInput, { FileUploadProps } from './FileUploadInput';

export type OnRemoveFunction = () => void;
export type RenderFileFunction = (file: HnpFile, onRemove?: OnRemoveFunction) => ReactNode;

interface ControlledFileUploadProps extends FileUploadProps {
  renderFile?: RenderFileFunction;
  onRemove?: () => void;
  label?: string;
}

function ControlledFileUploadInput(props: ControlledFileUploadProps) {
  const { uuid, onUUID, renderFile, onRemove, label } = props;
  const file = useFile(uuid);
  
  if (file) {
    return (
      <Card style={{ borderWidth: '1px', borderStyle: 'solid' }} outline color='grey' body>
        { Boolean(label) && (
          <CardTitle tag="label">{ label }</CardTitle>
        )}
        { renderFile ? renderFile(file, onRemove) : (
          <Alert toggle={onRemove}>
            <a href={file.url} target='_blank' rel='noreferrer noopener'>
              { file.url }
            </a>
          </Alert>
        )}
        <hr />
        <div>
          <FileUploadInput onUUID={onUUID} />
        </div>
      </Card>
    );
  }

  return (
    <Card style={{ borderWidth: '1px', borderStyle: 'solid' }} outline color='grey' body>
      { Boolean(label) && (
        <CardTitle tag="label">{ label }</CardTitle>
      )}
      <FileUploadInput {...props} />
    </Card>
  );
}

export default ControlledFileUploadInput;