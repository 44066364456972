
import React, { useMemo } from 'react';
import DataTable from 'components/Table/DataTable';
import { gql, useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { RowAction } from 'components/Table/RowActionItem';
import { confirm } from 'components/custom/Confirm';
import { useHistory } from 'react-router-dom';

const query = gql`
  query MeetingParticipants($meeting_id: String $date: String) {
    meeting_participants(meeting_id: $meeting_id date: $date) {
      name
      meeting_uuid
      company
      registrant_id
    }
  }
`;

const add_contact_mutation = gql`
  mutation AddContact($registrant_id: String $email: String $meeting_uuid: String!) {
    add_contact(registrant_id: $registrant_id email: $email meeting_uuid: $meeting_uuid) {
      remaining
      contact {
        company
        name
        email
        phone
        meeting_location
        meeting_date
        created_at
      }
    }
  }
`;

interface Props {
  meeting_id: string;
  date: number | string;
}

export default function MeetingParticipantsTableCard({ meeting_id, date }: Props) {
  const [addContact] = useMutation(add_contact_mutation);
  const history = useHistory();

  const formattedDate = useMemo(() =>
    typeof date === 'string' ? date : format(date || 0, 'yyyy-MM-dd'), 
    [date]
  );

  const { data } = useQuery(query, {
    variables: { 
      meeting_id, 
      date: formattedDate
    }
  });

  const participants = useMemo(() => {
    return data?.meeting_participants || [];
  }, [data]);

  const columns = useMemo(() => [{
    Header: 'Name',
    accessor: 'name'
  }, {
    Header: 'Company',
    accessor: 'company'
  }], []);

  const actions = useMemo<Array<RowAction>>(() => [{
    name: 'action-add-neighbor',
    icon: 'icon-plus',
    color: 'primary',
    title: 'Add Neighbor',
    onAction: async (name, row) => { 
      const confirmed = await confirm(
        `Are you sure wish to add "${row.name}" to your Neighbors?`, 
        'Add Neighbor'
      );

      if (confirmed) {
        const { data } = await addContact({
          variables: {
            meeting_uuid: row.meeting_uuid,
            registrant_id: row.registrant_id,
            email: row.email
          }
        });

        console.log('addContact', { data });

        history.push('/neighbors');
      }
    }
  }], [addContact, history]);

  return (
    <DataTable
      title={`Meetings Participants (${meeting_id})`}
      data={participants} 
      columns={columns} 
      initialSort='name'
      actions={actions}
      ascending
      />
  );
}