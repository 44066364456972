
import React, { useCallback, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Row, Col, CardBody, CardTitle, Card, Form, FormGroup, FormText, Input, Label, CardFooter, Button, Alert } from 'reactstrap';
import { useParams } from 'react-router-dom';
import StripeCreditCardInput from 'components/custom/StripeCreditCardInput';
import Stripe from 'stripe';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import usePricing, { centsToDollars } from 'api/usePricing';
import useDebounce from 'hooks/useDebounce';
import ZipsInput from 'components/custom/ZipsInput';
import Asterisk from 'components/custom/Asterisk';
import ControlledZipsInput from 'components/custom/ControlledZipsInput';
import { format } from 'date-fns/esm';

const TODAY = format(new Date(), 'yyyy-MM-dd');

const query = gql`
  query GetPendingBusiness($uuid: ID!) {
    pending_business(uuid: $uuid) {
      company
      name {
        first
        last
      }
      email
      data {
        pending
        category_uuid
        zips
        type
      }
    }
  }
`;

const processPaymentMutation = gql`
  mutation ProcessPendingBusinessPayment($data: PendingPaymentData!) {
    process_pending_payment(data: $data) {
      magic_code
      business {
        company
        name {
          first
          last
        }
        email
      }
    }
  }
`;

const ProcessPendingBusiness = () => {
  const { uuid } = useParams<{uuid: string}>();
  const { data } = useQuery(query, { variables: { uuid }});
  const [processPayment, { loading, data: mutationData, error: mutationError }] = useMutation(processPaymentMutation, {
    errorPolicy: 'all'
  });
  const { register, control, handleSubmit, watch, errors } = useForm();
  const [split, setSplit] = useState(false);
  const values = watch();
  const business = data?.pending_business;
  const { pricing, loading: priceLoading } = usePricing({ 
    type: business?.data?.type, 
    coupon: useDebounce(values?.payment?.coupon, 500),
    zips: values?.zips
  });

  const isLocal = business?.data?.type === 'LOCAL';

  const onSubmit = useCallback(async (data: any) => {
    try {
      processPayment({
        variables: { data }
      });
    } catch (e) {
      console.error('processPayment', e);  
    }
  }, [processPayment]);

  if (mutationData?.process_pending_payment) {
    const { company, name, email } = mutationData.process_pending_payment?.business;

    return (
      <div className='content'>
        <Row>
          <Col>
            <code>
              <pre>{ JSON.stringify({ mutationData }) }</pre>
            </code>
          </Col>
          <Col>
            <Card>
              <CardBody>
                <CardTitle>Rgistration Complete</CardTitle>
                <p>{ company }</p>
                <p>{ name?.first } { name?.last }</p>
                <p>{ email }</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }

  if (!business) {
    return (
      <p>Loading...</p>
    );
  }

  return (
    <div className='content'>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <input type='hidden' ref={register} name='type' value={business?.data?.type} />
            <input type='hidden' ref={register} name='business_uuid' value={uuid} />
            <input type='hidden' ref={register} name='category_uuid' value={business?.data?.category_uuid} />
            <Input type='hidden' innerRef={register({ valueAsNumber: true })} name='payment.amount' value={pricing?.total} />
            <Card>
              <CardBody>
                <CardTitle>Process Payment</CardTitle>
                <hr />
                <p>{ business.company }</p>
                <p>{ business.name?.first } { business.name?.last }</p>
                <p>{ business.email }</p>
                <p>{ business.data?.type } Listing</p>
                <hr />
                { isLocal && (
                  <ControlledZipsInput 
                    name='zips'
                    control={control}
                    defaultValue={business.data?.zips}
                    required
                    errors={errors}
                    />
                )}
                <hr />
                <FormGroup>
                  <Label>Credit Card Info<Asterisk /></Label>
                  <Controller
                    name='payment.token'
                    control={control}
                    rules={{ 
                      required: !pricing || pricing.total >= 50 ? 'Please enter a Credit Card!' : false, 
                      setValueAs: v => v ? { id: v.id } : null 
                    }}
                    defaultValue={null}
                    render={({ value, onChange }) => (
                      <div>
                        <StripeCreditCardInput token={value} onToken={onChange} />
                      </div>
                    )} />
                  <ErrorMessage as={FormText} color='danger' name='payment.token' errors={errors} />
                </FormGroup>
                <hr />
                <FormGroup>
                  <Label>Discount Code</Label>
                  <Input innerRef={register} name='payment.coupon' />
                  <ErrorMessage as={FormText} color='danger' name='payment.coupon' errors={errors} />
                </FormGroup>
                <hr />
                <FormGroup check>
                  <Label check>
                    <input 
                      style={{ opacity: 100, visibility: 'visible', position: 'relative' }} 
                      type='checkbox' 
                      value={split ? 'on' : 'off'} 
                      onChange={() => setSplit(s => !s)} 
                      />
                    {' '}
                    Split/Defer Payment
                  </Label>
                </FormGroup>
                { split && (
                  <>
                    <hr />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Date</Label>
                          <Input
                            innerRef={register}
                            type='date'
                            name='payment.startDate'
                            defaultValue={TODAY}
                            />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Number of Payments</Label>
                          <Input
                            innerRef={register({ valueAsNumber: true })}
                            type='select'
                            name='payment.count'
                            >
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Payment Frequency</Label>
                          <Input
                            innerRef={register}
                            type='select'
                            name='payment.frequency'
                            disabled={!(values?.payment?.count) || values?.payment?.count < 2}
                            >
                            <option value='WEEKLY'>Weekly</option>
                            <option value='BIWEEKLY'>Bi-Weekly</option>
                            <option value='MONTHLY'>Monthly</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                )}
                <hr />
                <CardFooter>
                  { Boolean(mutationError) && (
                    <div>
                      <Alert color='warning'>
                        { mutationError?.message }
                      </Alert>
                      <hr />
                    </div>
                  )}
                  <Button type='submit' disabled={loading || priceLoading}>
                    { priceLoading ? 'Calculating Price...' : 'Process Payment for ' + centsToDollars(pricing?.total) }
                  </Button>
                </CardFooter>
              </CardBody>
            </Card>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default ProcessPendingBusiness;