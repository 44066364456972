
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import ControlledFileUploadInput, { RenderFileFunction } from './ControlledFileUploadInput';

export interface HookFormFileInputProps {
  name: string;
  control: Control;
  defaultValue?: string;
  label?: string;
}

interface RenderableHookFromFileInputProps extends HookFormFileInputProps {
  renderFile?: RenderFileFunction;
}

function HookFormFileInput({ name, control, defaultValue, label, renderFile }: RenderableHookFromFileInputProps) {

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ value, onChange }) => (
        <ControlledFileUploadInput 
          uuid={value} 
          onUUID={onChange} 
          label={label}
          renderFile={renderFile}
          />
      )}
      />
  );
}

export default HookFormFileInput;