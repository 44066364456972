

import { logout } from "api/AuthContext";
import React from "react";
import { Button, Spinner } from "reactstrap";

function LoggingIn() {
  return (
    <div className='wrapper wrapper-full-page' style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center'
    }}>
      <h1>Loading...</h1>
      <Spinner style={{ width: '3rem', height: '3rem' }} color='light' />
      <Button onClick={logout} color='warning'>
        Logout
      </Button>
    </div>
  );
}

export default LoggingIn;
