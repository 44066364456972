
import { gql } from "@apollo/client";
import { client } from "api/GraphQL";

const mutation = gql`
  mutation CreateFileAndSignURL($name: String! $type: String $size: Int!) {
    s3_signed_file(name: $name type: $type size: $size) {
      signedRequest
      file {
        uuid
        url
      }
    }
  }
`;

interface S3UrlParams {
  name: string;
  type: string;
  size: number;
}

interface SignedFileResult {
  s3_signed_file: {
    signedRequest: string;
    file: {
      uuid: string;
      url: string;
    };
  }
}

export default async function getS3UploadUrl({ name, type, size }: S3UrlParams) {
  const { data } = await client.mutate<SignedFileResult>({
    mutation,
    variables: {
      name, type, size 
    }
  });

  return data?.s3_signed_file || { signedRequest: '', file: null };
}