

import React, { Fragment, useRef } from "react";
import { Switch, Redirect, useLocation } from "react-router-dom";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import useBrandText from "layouts/useBrandText";
import usePerfectScrollbar from "layouts/usePerfectScrollbar";
import useToggleSidebar from "components/Sidebar/useToggleSidebar";

import routes, { getRoutes, filterRoutes } from "routes";

import logo from 'assets/img/hnp.png';
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import useUser, { useIsAdministrator } from "api/useUser";

function Admin({ layout = '/' }) {
  const mainPanelRef = useRef(null);
  const location = useLocation();
  usePerfectScrollbar(mainPanelRef.current);
  const [sidebarOpened, toggleSidebar] = useToggleSidebar();
  const brandText = useBrandText();
  const user = useUser();
  const isAdmin = useIsAdministrator();
  const notFoundPath = isAdmin ? '/admin/dashboard' : '/listing';

  return (
    <BackgroundColorContext.Consumer>
      {({ color, changeColor }) => (
        <Fragment>
          <div className="wrapper">
            <Sidebar
              routes={filterRoutes(routes, layout || '/', user, true)}
              logo={{
                outerLink: "/",
                text: 'HNP Admin',
                imgSrc: logo,
              }}
              toggleSidebar={toggleSidebar}
            />
            <div className="main-panel" ref={mainPanelRef} data={color}>
              <AdminNavbar
                brandText={brandText}
                toggleSidebar={toggleSidebar}
                sidebarOpened={sidebarOpened}
              />
              <Switch>
                {getRoutes(routes, layout || '/', user)}
                <Redirect from="*" to={notFoundPath} />
              </Switch>
              {
                // we don't want the Footer to be rendered on map page
                location.pathname === "/maps" ? null : <Footer fluid />
              }
            </div>
          </div>
          <FixedPlugin bgColor={color} handleBgClick={changeColor} />
        </Fragment>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default Admin;
