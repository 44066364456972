
import React, { useMemo } from 'react';
import DataTable from 'components/Table/DataTable';
import { confirm } from 'components/custom/Confirm';
import { gql, useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { RowAction } from 'components/Table/RowActionItem';
import { Popover, PopoverBody } from 'reactstrap';
import { Column, ColumnWithLooseAccessor } from 'react-table';

const query = gql`
  query Businesses {
    businesses {
      company
      type
      name {
        first
        last
      }
      website
      email
      phone
      created_at
    }
  }
`;

interface Props {
  onSelect?: (cohost: any) => void;
}

function typeToType(type: string) {
  type = type.replace('business-', '');

  return type || 'business';
}

function addUrlScheme(website: string) {
  if (!website.startsWith('http')) {
    return 'http://' + website;
  }

  return website;
}

export default function BusinessesTableCard({ onSelect }: Props) {
  const { data } = useQuery(query);

  const columns = useMemo(() => [{
    Header: 'Company',
    accessor: 'company',
    Cell: (props: any) => props.row.original.website ? 
      <a href={addUrlScheme(props.row.original.website)} title={props.row.original.website} target='_blank' rel='noreferrer noopener'>{ props.value }</a> : 
      props.value
  }, {
    Header: 'Type',
    accessor: 'type',
    Cell: (props: any) => typeToType(props.value)
  }, {
    id: 'name',
    Header: 'Name',
    accessor: (row: { name: { first: string; last: string; } }, index: number) => `${row.name?.first} ${row.name?.last}`,
    Cell: (props: any) => (
      <span style={{ textTransform: 'capitalize' }}>
        { props.value }
      </span>
    )
  }, {
    Header: 'Email',
    accessor: 'email',
    Cell: (props: any) => <a href={`mailto:${props.value}`}>{ props.value }</a>
  }, {
    Header: 'Phone',
    accessor: 'phone',
    Cell: (props: any) => <a href={`tel:${props.value}`}>{ props.value }</a>
  }, {
    Header: 'Created',
    accessor: 'created_at',
    Cell: ({ value }: { value?: number }) => value ? format(value, 'MMM do, yyyy') : ''
  }], []);

  const businesses = useMemo(() => data?.businesses || [], [data]);

  return (
    <DataTable
      title='Businesses'
      data={businesses} 
      columns={columns} 
      initialSort='created_at'
      />
  );
}