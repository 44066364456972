
import React from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { useParams } from 'react-router-dom';
import MeetingParticipantsTableCard from 'widgets/MeetingParticipantsTableCard';

interface RouteParams {
  meeting_id: string;
  date: string;
}

const MeetingParticipants = () => {
  const { meeting_id, date } = useParams<RouteParams>();

  return (
    <div className='content'>
      <Row>
        <Col>
          <Alert color='warning'>
            <p>Please only add people to your neighbors if you've been in a breakout room with or otherwise interacted with them!</p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <MeetingParticipantsTableCard
            meeting_id={meeting_id}
            date={date}
            />
        </Col>
      </Row>
    </div>
  );
}

export default MeetingParticipants;