
import React, { useMemo } from 'react';
import DataTable from 'components/Table/DataTable';
import { gql, useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { Row, Col, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import useUser from 'api/useUser';
import { confirm } from 'components/custom/Confirm';
import { RowAction } from 'components/Table/RowActionItem';

const query = gql`
  query GetEvents {
    events {
      uuid
      title
      description
      start
    }
  }
`;

const delete_event_mutation = gql`
  mutation DeleteEvent($uuid: ID!) {
    delete_event(uuid: $uuid)
  }
`;

export function EventsTableCard() {
  const user = useUser();
  const { data, refetch } = useQuery(query, {
    fetchPolicy: 'cache-and-network'
  });
  const [deleteEvent] = useMutation(delete_event_mutation);

  const columns = useMemo(() => {
    const columns = [{
      Header: 'Title',
      accessor: 'title',
      Cell: ({ value, row }: { value: string, row: any }) => 
        <Link to={`/events/${row.original.uuid}`}>{ value }</Link>
    }, {
      Header: 'Start',
      accessor: 'start',
      Cell: ({ value }: { value: string, row: any }) => value ? 
        format(new Date(value), 'MMM do, yyyy h:mma') : 
        ''
    }];

    if (['superadmin', 'admin', 'manager', 'sales'].includes(user?.role || '')) {
      columns.push({
        Header: 'Edit',
        accessor: 'uuid',
        Cell: ({ value }: { value: string, row: any }) => (
          <Link to={`/events/${value}/edit`}>Edit</Link>
        )
      })
    }

    return columns;
  }, [user]);

  const actions = useMemo<Array<RowAction>>(() => {
    if (['superadmin', 'admin', 'manager', 'sales'].includes(user?.role || '')) {
      return [{
        name: 'action-delete-event',
        icon: 'icon-trash-simple',
        color: 'primary',
        title: 'Delete Event',
        onAction: async (name: any, row: any) => { 
          const confirmed = await confirm(
            `Are you sure wish to delete "${row.title}"`, 
            'Delete Event'
          );
    
          if (confirmed) {
            const { data } = await deleteEvent({
              variables: {
                uuid: row.uuid
              }
            });
    
            console.log('addContact', { data });
            refetch();
          }
        }
      }];
    }

    return [];
  }, [deleteEvent, refetch]);

  const events = useMemo(() => data?.events || [], [data]);

  return (
    <DataTable
      title='Events'
      data={events} 
      columns={columns} 
      actions={actions}
      initialSort='starts'
      ascending
      />
  );
}

const Events = () => {
  const user = useUser();
  
  return (
    <div className='content'>
      { Boolean(user && user.role !== 'user' && user.role !== 'guest') && (
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Link to='/admin/add-event'>Add Event</Link>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <EventsTableCard />
        </Col>
      </Row>
    </div>
  );
}

export default Events;