
import { useState, useCallback } from "react";

export default function useToggleSidebar() {
  const [sidebarOpened, setsidebarOpened] = useState<boolean>(
    document.documentElement.className.indexOf("nav-open") !== -1
  );

  // this function opens and closes the sidebar on small devices
  const toggleSidebar = useCallback(() => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(sidebarOpened => !sidebarOpened);
  }, []);

  return [sidebarOpened, toggleSidebar];
}